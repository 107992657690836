import { AESDecrypt } from "../../global/Crypto-Helper";

const grantPermission = (requestedRole) => {
  const { role } = JSON.parse(localStorage.getItem("user"));
  return requestedRole === AESDecrypt(role?.roleName);
};

/*
  Код для теста

  const grantPermission = (requestedAuthorities, currentUserAuthorities) => {
    return requestedAuthorities.every(requestedAuthority => currentUserAuthorities.includes(requestedAuthority));
  };

  const requestedAuthorities = [1, 2, 3];
  const currentUserAuthorities = [1, 2, 4, 5]

  console.log(grantPermission(requestedAuthorities, currentUserAuthorities))
*/

export default grantPermission;
