import React from 'react';
import { Button } from '../../../buttons/Button';
import { Heading } from '../../heading/Heading';
import { StockLayer } from '../StockLayer/StockLayer';
import './slider.scss';

export const StockSlide = ({
	title,
	img = '/images/stocks.png',
	subTitle,
	desc,
	stock = false,
	stockTitle = '',
	btnTitle = 'ЗАКАЗАТ СЕЙЧАС'
}) => {
	return (
		<>
			<div className='container slide'>
				<div className='left'>
					<Heading
						title={title}
						width='500px'
						fontSize='36px'
						align='left'
						justifyContent='space-between'
					>
						{stock ? <StockLayer>{stockTitle}</StockLayer> : null}
					</Heading>
					<h3>{subTitle}</h3>
					<p>{desc.length > 255 ? `${desc.slice(0, 255)}...` : desc}</p>
					<Button
						label={btnTitle}
						width='250px'
						hover='black'
						fontSize='14px'
						height='30px'
						btnFontWeight='true'
					/>
				</div>
				<div className='right'>
					<div className='stockImage'>
						<img src={img} alt={img} />
					</div>
				</div>
			</div>
		</>
	);
};
