import { useEffect } from 'react';
import { Main } from './pages/main/Main';
import LoginPage from './pages/LoginPage/LoginPage';
import Goods from './pages/Goods/Goods';
import ProvidersCategories from './pages/Providers/ProvidersCategories/ProvidersCategories';
import Basket from './pages/Basket/Basket';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { Routes, Route } from 'react-router';
import { AboutGood } from './pages/AboutGood/AboutGood';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProvidersList } from './pages/Providers/ProvidersList/ProvidersList';
import { Provider } from './pages/Providers/Provider/Provider';
import { AboutToProviders } from './pages/about/aboutPages/AboutToProviders';
import { AboutToCustomers } from './pages/about/aboutPages/AboutToCustomers';
import AuthenticatedContent from './common/AuthenticatedContent';
import Dashboard from './pages/admin/Dashboard/Dashboard';
import withServices from './hocs/withServices';
import { BrowserRouter as Router } from 'react-router-dom';
import PublicPages from './routes/public/PublicPages';
import Registration from './pages/Registration/Registration';
import { TermsPage } from './pages/Registration/TermsPage/TermsPage';
import OrderRegistration from './pages/OrderRegistration/OrderRegistration';
import MyCabinet from './pages/MyCabinet/MyCabinet';
import Promotions from './pages/Promotions/Promotions';
import ScrollToTop from './common/ScrollToTop';
import LoginResetPage from './pages/LoginResetPage/LoginResetPage';
import ResetPassPage from './pages/LoginResetPage/ResetPassPage/ResetPassPage';
import SuccessActivated from './pages/Registration/SuccessActivated/SuccessActivated';

const App = () => {
	return (
		<>
			<ToastContainer />
			<Router basename='/'>
				<ScrollToTop />
				<Routes>
					<Route
						path='/'
						element={
							<PublicPages>
								<Main />
							</PublicPages>
						}
					/>
					<Route
						path='/login'
						element={
							<PublicPages>
								<LoginPage />
							</PublicPages>
						}
					/>
					<Route
						path='/registration'
						element={
							<PublicPages>
								<Registration />
							</PublicPages>
						}
					/>
					<Route
						path='/terms'
						element={
							<PublicPages>
								<TermsPage />
							</PublicPages>
						}
					/>
					<Route
						path='/category/:catId'
						element={
							<PublicPages>
								<Goods />
							</PublicPages>
						}
					/>
					<Route
						path='/providers-categories'
						element={
							<PublicPages>
								<ProvidersCategories categoryTitle />
							</PublicPages>
						}
					/>
					<Route
						path='/provider/:providerId'
						element={
							<PublicPages>
								<Provider categoryTitle />
							</PublicPages>
						}
					/>
					<Route
						path='/providers-categories/:catId/providers'
						element={
							<PublicPages>
								<ProvidersList />
							</PublicPages>
						}
					/>
					<Route
						path='/basket'
						element={
							<PublicPages>
								<Basket />
							</PublicPages>
						}
					/>
					<Route
						path='/order-registration'
						element={
							<PublicPages>
								<OrderRegistration />
							</PublicPages>
						}
					/>
					<Route
						path='/provider/:providerId/category/:catId/good/:goodId'
						element={
							<PublicPages>
								<AboutGood />
							</PublicPages>
						}
					/>
					<Route
						path='/to-providers'
						element={
							<PublicPages>
								<AboutToProviders />
							</PublicPages>
						}
					/>
					<Route
						path='/to-customers'
						element={
							<PublicPages>
								<AboutToCustomers />
							</PublicPages>
						}
					/>
					<Route
						path='*'
						element={
							<PublicPages>
								<NotFoundPage />
							</PublicPages>
						}
					/>
					<Route
						path='/my-cabinet'
						element={
							<PublicPages>
								<MyCabinet />
							</PublicPages>
						}
					/>
					<Route
						path='/promotions'
						element={
							<PublicPages>
								<Promotions />
							</PublicPages>
						}
					/>
					<Route
						path='/reset-password'
						element={
							<PublicPages>
								<LoginResetPage />
							</PublicPages>
						}
					/>
					<Route
						path='/reset-pass'
						element={
							<PublicPages>
								<ResetPassPage />
							</PublicPages>
						}
					/>
					<Route
						path='/success-activated'
						element={
							<PublicPages>
								<SuccessActivated />
							</PublicPages>
						}
					/>
					{/* <Route
					path='/admin'
					element={
						<AuthenticatedContent role={'ROLE_ADMIN'}>
							<Dashboard />
						</AuthenticatedContent>
					}
				/> */}
				</Routes>
			</Router>
		</>
	);
};

export default App;
