import React from 'react';
import { Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';

function SuccessActivated() {
	return (
		<>
			<Grid sx={{ width: '100%', height: '650px' }}>
				<div className='page-container'>
					<Typography sx={{ fontSize: '24px' }}>
						<Grid sx={{ textAlign: 'center' }}>
							<CheckCircleIcon sx={{ fontSize: '50px' }} />
						</Grid>
						Ваш аккаунт успешно активирован!
						<br />
					</Typography>
					<Grid
						sx={{
							textAlign: 'center',
							marginTop: '20px',
							color: 'blue',
							textDecoration: 'underline'
						}}
					>
						<Link to='/login'>Войти</Link>
					</Grid>
				</div>
			</Grid>
		</>
	);
}

export default SuccessActivated;
