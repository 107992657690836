import authAxios from '../common/authAxios';
import axios from 'axios';
import Constants from '../common/constants';

class FileUploadService {
	async uploadDocument(documentFile, type, callback) {
		const formData = new FormData();
		formData.append('documentFile', documentFile);

		return await axios.post(`/file/upload-document?type=${type}`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: event => {
				typeof callback === 'function' &&
					callback((event.loaded / event.total) * 100);
			}
		});
	}

	async uploadDocuments(documentFiles, type, callback) {
		const formData = new FormData();
		for (let file of documentFiles) {
			formData.append('documentFiles', file);
		}
		// formData.append('documentFile', documentFiles);
		console.log(formData);
		return await authAxios.post(
			`/file/upload-documents?type=${type}`,
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
				onUploadProgress: event => {
					typeof callback === 'function' &&
						callback((event.loaded / event.total) * 100);
				}
			}
		);
	}

	static async uploadPhotos(image) {
		const formData = new FormData();
		// for (let file of image) {
		formData.append('file', image);
		// }
		return await axios.post(
			`${Constants.API_BASE_URL}/file/upload-photos`,
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' }
				// onUploadProgress: event => {
				// 	typeof callback === 'function' &&
				// 		callback((event.loaded / event.total) * 100);
				// }
			}
		);
	}

	static getDocument(fileId, fileName) {
		// fileName = fileName.substring(0, fileName.lastIndexOf('.'));
		authAxios
			.get(`/file/get-document?id=${fileId}`)
			.then(response => {
				const extensionHeaderValue = response.headers['extension'];
				const linkSource =
					extensionHeaderValue === 'pdf'
						? `data:application/pdf;base64,${response.data}`
						: `data:application/octet-stream;base64,${response.data}`;

				// Почему-то 'substr()' при отсутствии нужной строки возвращает всю строку целиком...
				if (fileName === fileName.substr(fileName.lastIndexOf('.') + 1)) {
					fileName += `.${extensionHeaderValue}`;
				}

				const downloadLink = document.createElement('a');
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			})
			.catch(error => {
				console.log(error);
			});
	}
}

export default FileUploadService;
