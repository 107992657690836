import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './slick.scss';
import './slick-theme.scss';
import { StockSlide } from '../StockSlide/StockSlide';
import CircularProgress from '@mui/material/CircularProgress';
import PromotionService from '../../../../../services/promotionService';

function StocksSlider() {
	const [promotions, setPromotions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (localStorage.getItem('cityId') !== null) {
			setIsLoading(true);
			PromotionService.getActivePromotions(localStorage.getItem('cityId'))
				.then(response => {
					setPromotions(response.data.reverse());
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, []);

	const settings = {
		autoplay: true,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};

	return (
		<>
			<div className='slide-con' style={{ backgroundColor: '#D9D9D9' }}>
				<div className='container'>
					<div className='stock-slider' style={{ padding: '50px' }}>
						{isLoading ? (
							<>
								<div className='loading-block'>
									<CircularProgress />
								</div>
							</>
						) : (
							<>
								<Slider {...settings}>
									{promotions?.map((slide, i) => (
										<StockSlide
											key={i}
											title={slide.title}
											subTitle={slide.subTitle}
											desc={slide.description}
											// stock={slide.isActive}
											stockTitle={slide.stockTitle}
											img={slide?.photo[0]?.url}
										/>
									))}
								</Slider>
								{promotions?.length === 0 ? 'В этом городе нет акций!' : null}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default StocksSlider;
