import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Typography, Breadcrumbs } from '@mui/material';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import {
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails
} from '@mui/material';
import Constants from '../../common/constants';
import { ContentHeader } from '../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../common/helpers/HeadMenu/HeadMenu';
import Adverts from '../Adverts';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './basket.scss';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from 'react-redux';
import { StocksSign } from '../../common/helpers/main/Stocks/StocksSign/StocksSign';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withServices from '../../hocs/withServices';
import {
	addToCart,
	removeFromCart,
	minusQauntity,
	getTotals
} from '../../store/slices/Cart/cartSlice';

function Basket({ services }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cart = useSelector(state => state.cart);

	useEffect(() => {
		dispatch(getTotals());
	}, [cart]);

	const handleAddToCart = good => {
		dispatch(addToCart(good));
	};

	const handleRemoveFromCart = good => {
		dispatch(removeFromCart(good));
	};

	const handleMinusQuantity = good => {
		dispatch(minusQauntity(good));
	};

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme>
				<h1>Корзина</h1>
				<Breadcrumbs
					className='breadcrumbs'
					aria-label='breadcrumb'
					sx={{ color: 'rgba(0,0,0,.20)' }}
				>
					<Link underline='hover' color='inherit' to='/'>
						Главная
					</Link>
					<Link underline='hover' color='inherit' to='/providers-categories'>
						Каталог
					</Link>
					<Typography color='text.primary'>Корзина</Typography>
				</Breadcrumbs>
				<Box className=''>
					<Grid>
						<div className='cart-items'>
							{cart.cartItems.length === 0 ? (
								<div className='noBasket'>
									<Typography
										sx={{
											textAlign: 'center',
											marginTop: '20px',
											fontSize: '20px'
										}}
									>
										Ваша корзина пусто
									</Typography>
									<Button
										sx={{ margin: '20' }}
										onClick={() => navigate(-1)}
										startIcon={<ArrowBackIcon />}
									>
										Вернуться назад
									</Button>
								</div>
							) : (
								<div className='basket'>
									{cart.cartItems?.map((providerItem, j) => (
										<Accordion
											key={j}
											defaultExpanded={!j}
											className='basket-accordion'
										>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<img
													src={providerItem?.logotype[0]?.url}
													alt=''
													width={100}
												/>
												<Typography className='basket-details'>
													<strong>{providerItem.name}</strong>
													<div className='details'>
														<span>
															{' '}
															Сумма: {providerItem?.cartTotalAmount}₸
														</span>
														<span> Товаров: {providerItem?.cartQuantity}</span>
													</div>
												</Typography>
												<Typography></Typography>
											</AccordionSummary>
											<AccordionDetails>
												{providerItem?.goods?.map((cartItem, i) => (
													<div className='cart-item' key={i}>
														<div className='cart-num'>{++i}</div>
														<div className='cart-info'>
															<div className='img'>
																<img
																	src={
																		cartItem?.mainPhoto?.url
																			? cartItem?.mainPhoto?.url
																			: `${Constants.BASE_URL}static/media/logo-black.2496a132b014dd3006c66cb13eebd443.svg`
																	}
																	alt=''
																/>
																<div className='stock-sign-con'>
																	{cartItem?.discount ? (
																		<div className='stock-sign'>
																			<StocksSign>
																				{cartItem?.discount}
																			</StocksSign>
																		</div>
																	) : null}
																</div>
															</div>
															<div className='desc'>
																<h4>{cartItem.title}</h4>
																<p>{cartItem.short_desc}</p>
																<h5>Фирма: {cartItem.provider?.name}</h5>
															</div>
															<div className='actions'>
																<p className='price'>
																	{cartItem?.discount ? (
																		<>
																			{cartItem?.discountprice *
																				cartItem.cartQuantity}{' '}
																			₸
																		</>
																	) : (
																		<>
																			{cartItem.price * cartItem.cartQuantity} ₸
																		</>
																	)}
																	<br />
																	<span>
																		{cartItem?.discount ? (
																			<>
																				<span className='old-price'>
																					{cartItem?.price} ₸
																				</span>
																				{` `}
																				<span className='new-price'>
																					{cartItem?.discountprice} ₸/шт
																				</span>
																			</>
																		) : (
																			<>
																				<span>{cartItem?.price} ₸/шт</span>
																			</>
																		)}
																	</span>
																	{cartItem.discount ? (
																		<p className='stock-title'>
																			Цена со скидкой {cartItem?.discount}%
																		</p>
																	) : null}
																</p>
																<div className='button-group'>
																	<div className='btn-gr'>
																		<button
																			onClick={() =>
																				handleMinusQuantity(cartItem)
																			}
																		>
																			<RemoveIcon />
																		</button>
																		<input
																			type='number'
																			value={cartItem.cartQuantity}
																		/>
																		<span> шт</span>
																		<button
																			onClick={() => handleAddToCart(cartItem)}
																		>
																			<AddIcon />
																		</button>
																	</div>
																	<button
																		className='delete-btn'
																		onClick={() =>
																			handleRemoveFromCart(cartItem)
																		}
																	>
																		<DeleteForeverIcon />
																	</button>
																</div>
															</div>
														</div>
													</div>
												))}
											</AccordionDetails>
										</Accordion>
									))}
									<div className='cart-info-bottom'>
										<Grid className='left'>
											Общий количество товаров:
											<span> {cart.cartTotalQuantity}</span>
										</Grid>
										<Grid className='right'>
											<p>
												<strong>Сумма:</strong>{' '}
												<span>
													{Number(cart.priceWithoutDiscount).toLocaleString()} ₸
												</span>
											</p>
											{cart.priceWithoutDiscount - cart.cartTotalAmount !==
											0 ? (
												<p>
													<strong>Cкидка от поставщиков:</strong>{' '}
													<span>
														-
														{Number(
															cart.priceWithoutDiscount - cart.cartTotalAmount
														).toLocaleString()}{' '}
														₸
													</span>
												</p>
											) : null}
											<p>
												<strong>Cкидка от SaudaLine(1%):</strong>{' '}
												<span>
													-
													{Number(
														Math.round((cart.cartTotalAmount / 100) * 1)
													).toLocaleString()}{' '}
													₸
												</span>
											</p>
											<p className='itogo'>
												<strong>Итого:</strong>{' '}
												<strong>
													<span>
														{Number(
															cart.cartTotalAmount -
																Math.round((cart.cartTotalAmount / 100) * 1)
														).toLocaleString()}{' '}
														₸
													</span>
												</strong>
											</p>
										</Grid>
									</div>
									<div className='btn-order'>
										<button
											style={{ margin: '20' }}
											onClick={() => navigate(-1)}
										>
											<ArrowBackIcon />
											Вернуться назад
										</button>
										<Link to='/order-registration'>
											<button>Оформить заказ</button>
										</Link>
									</div>
								</div>
							)}
						</div>
					</Grid>
				</Box>
			</ContentScheme>
		</>
	);
}

export default withServices()(Basket);
