import React, { useState } from 'react';
import { Typography, TextField, Button, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import logo from '../../common/assets/images/logo-black.svg';
import AuthService from '../../services/authService';
const CustomDiv = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh'
});

const CustomTextField = styled(TextField)({
	marginBottom: '20px',
	'& label.Mui-focused': {
		color: '#FCA522'
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#FCA522'
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#FCA522'
		},
		'&:hover fieldset': {
			borderColor: '#FCA522'
		},
		'&.Mui-focused fieldset': {
			borderColor: '#FCA522'
		}
	}
});

function LoginResetPage() {
	const [passwordError, setPasswordError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const { register, handleSubmit } = useForm();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const onSubmit = data => {
		setLoading(true);
		AuthService.resetPassword(data)
			.then(response => {
				alert(
					'На вашу почту выслана ссылка для полного восстановления аккаунта'
				);
				navigate('/');
			})
			.catch(error => setErrorMessage(error))
			.finally(() => {
				setLoading(false);
			});
	};

	const onTypePass = () => {
		const passValue = document.getElementById('pass').value;
		const confPassValue = document.getElementById('confPass').value;

		if (passValue === confPassValue) {
			setPasswordError(false);
			setErrorMessage('');
		} else {
			setPasswordError(true);
			setErrorMessage('Пароли не совпадают');
		}
	};

	return (
		<>
			{loading ? (
				<CustomDiv>
					<CircularProgress />
				</CustomDiv>
			) : (
				<div className='page-container'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Link to='/' className='logo-login'>
							<img className='logo' src={logo} alt='' />
						</Link>
						<Typography>Восстановление пароля</Typography>
						<CustomTextField
							required
							name='email'
							type='text'
							placeholder=''
							{...register('email')}
							label='Логин или эл. почта'
							variant='outlined'
						/>
						<br />
						{passwordError && (
							<Typography variant='body1' color='error'>
								{`${errorMessage}`}
							</Typography>
						)}
						<CustomTextField
							required
							{...register('password')}
							id='pass'
							name='password'
							type='password'
							label='Пароль'
							variant='outlined'
							onChange={onTypePass}
						/>
						<CustomTextField
							required
							id='confPass'
							name='confirmPassword'
							type='password'
							label='Повторите пароль'
							variant='outlined'
							onChange={onTypePass}
						/>
						<div className='buttons'>
							<Button disabled={passwordError} type='submit'>
								Восстановить
							</Button>
						</div>
					</form>
				</div>
			)}
		</>
	);
}

export default LoginResetPage;
