import React from 'react';
import { ContentHeader } from '../../common/Content/ContentHeader/ContentHeader';
import { HeadMenu } from '../../common/helpers/HeadMenu/HeadMenu';
import { ContentScheme } from '../../common/helpers/contentScheme/ContentScheme';

export const NotFoundPage = () => {
  return (
    <>
        <ContentHeader />
        <HeadMenu />
        <ContentScheme >
          <h1
          style={{
            width:'100%',
            textAlign: 'center',
            marginBottom: '20px'
          }}
          >Страница в разработке</h1>
        </ContentScheme>
    </>
  )
}