import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import { contacts, formdata, socialLinks } from '../assets/data';
import './footer.scss';
import { Button } from '../helpers/buttons/Button';
import { Container } from '@mui/system';

export const Footer = () => {
	return (
		<footer style={{ position: 'absolute', bottom: 0 }}>
			<Container>
				<div className='top'>
					<div className='left'>
						<Link to='/'>
							<img className='logo' src={logo} alt='' />
						</Link>
						<h4>{contacts.title}</h4>
						<p>
							<i className='fab fa-whatsapp'></i> {contacts.phone}
						</p>
						<p>
							<i className='far fa-envelope'></i> {contacts.email}
						</p>
						<p>
							<i className='far fa-map-marked-alt'></i> {contacts.address}
						</p>
					</div>
					<div className='right'>
						<form action=''>
							<input type='text' placeholder={formdata.email} />
							<textarea
								name=''
								id=''
								cols='30'
								rows='8'
								placeholder={formdata.textarea}
							></textarea>
							<Button
								label={formdata.btn}
								textShadow='true'
								width='280px'
								borderRadius='10px'
								fontSize='16px'
								btnType='submit'
							/>
						</form>
					</div>
				</div>
				<div className='container bottom'>
					<div className='socials'>
						<Link to={socialLinks.instagram}>
							<i className='fab fa-instagram'></i>
						</Link>
						<Link to={socialLinks.facebook}>
							<i className='fab fa-facebook-square'></i>
						</Link>
						<Link to={socialLinks.twitter}>
							<i className='fab fa-twitter-square'></i>
						</Link>
					</div>
				</div>
			</Container>
		</footer>
	);
};
