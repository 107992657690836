import { category, goods, company } from '../common/assets/data';
import axios from 'axios';
import Constants from '../common/constants';

const goodsService = {
	getGoodsByProviderId: ({ from, to, providerId }) => {
		return axios.get(
			`${Constants.API_BASE_URL}/product/get-by-provider?providerId=${providerId}&_start=${from}&_end=${to}`
		);
	},

	getGoodById: ({ goodId }) => {
		return axios.get(
			`${Constants.API_BASE_URL}/product/get-by-id?productId=${goodId}`
		);
	},

	getProvidersByType: ({ typeId }) => {
		const providers = company.filter(company => {
			return company.cat_id === typeId;
		});

		return new Promise((resolve, reject) => {
			resolve(providers);
		});
	},

	getCategories: ({ catId }) => {
		const categories = category.filter(category => {
			return category.id === catId;
		});

		return new Promise((resolve, reject) => {
			resolve(categories);
		});
	}
};

export default goodsService;
