import React from "react";
import { ContentHeader } from "../../../common/Content/ContentHeader/ContentHeader";
import { ContentScheme } from "../../../common/helpers/contentScheme/ContentScheme";
import { HeadMenu } from "../../../common/helpers/HeadMenu/HeadMenu";
import "./style.scss";

export const AboutToProviders = () => {
  return (
    <>
      <ContentHeader />
      <HeadMenu />
      <ContentScheme>
        <div className="AboutText">
          {/* <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "28px",
            }}
          >
            Компания Saudaline.kz занимается услугой продажи.
          </p> */}
          <p style={{ textAlign: "center", color: "#15c5e5" }}>
            Мы предоставляем возможность получать прямые заказы с торговых точек
            в режиме online.
            <br /> С Нами Вы сможете взять свой бизнес под контроль и сделать
            его успешным и прибыльным!
          </p>
          <p>
            База поставщиков в Казахстане «Saudaline.kz» создает все условия для
            того, чтобы смогли напрямую сделать заказ у фирмы. Это бесплатно и
            безопасно, так как пользоваться сервисом могут только владельцы
            торговых точек (магазинов)&nbsp; а также их работники располагающие
            доступом и фирмы. Цены на товары доступны только целевым группам
            пользователей. Авторизованные фирмы располагают возможностью
            загружать сведения о предлагаемых товарах в общую базу
            производителей. Владельцы магазинов просматривают электронный
            каталог и принимают заявку и выбирают дату поставки товара. Фирма
            делает доставку указанный срок по указанному адресу и принимают
            оплату.
          </p>
          <p>
            Saudaline.kz - это в первую очередь уникальная торговая система,
            пользоваться которой совершенно безопасно.
          </p>
          <ul>
            <li>
              Сервис не взимает плату за пользование функционалом – он доступен
              бесплатно.
            </li>
            <li>
              Ассортимент магазинов.&nbsp;Услугами сервиса пользуются владельцы
              более 5000 торговых точек Казахстана.&nbsp;
            </li>
          </ul>
          <span>
            (тоесть вы не плотите торговому представителю зарплату, проезд и %.
            С нами &nbsp;Вы экономите. Наша фирма предоставляет тарифы общего
            объема каждый месяц. За каждый миллион 50 тыс тенге.)
          </span>
          <p>
            Первый шаг, который нужно сделать на пути к успешности собственного
            бизнеса – это зарегистрироваться в базе прямых
            поставщиков.&nbsp;После регистрации вы получите индивидуальный логин
            и пароль от личного кабинета.&nbsp;Вы прикрепляете электронный
            каталог фирмы с ценами на каждый товар, по которым владельцы
            торговых точек смогут сделать заказ на нашем сайте.
          </p>
        </div>
      </ContentScheme>
    </>
  );
};
