import { useEffect } from 'react';
import axios from 'axios';
import Constants from '../common/constants';
import { AESEncrypt } from '../global/Crypto-Helper';
import authAxios from '../common/authAxios';

class AuthService {
	static async login(data) {
		const baseURL = Constants.API_BASE_URL;
		// if (!this.validateEmail(data.email)) {
		// 	return Promise.reject('Invalid email address format');
		// }

		try {
			const response = await axios.post(`${baseURL}/auth/login`, data);

			if (response.data.isActive) {
				this.setCredentials(response);
				return Promise.resolve(response);
			}
		} catch (error) {
			if (!error.response) {
				return Promise.reject('Internal server error');
			}

			const message = error.response.data.message;
			if (message) {
				if (message === 'Not verified') {
					this.sendVerificationEmail(data.email);
					return Promise.resolve(false);
				}

				return Promise.reject(message);
			}

			return Promise.reject('An error has occured');
		}
	}

	static async registration(data) {
		const baseURL = Constants.API_BASE_URL;
		let businessLisence;
		let alcoholLicense;
		if (!this.validateEmail(data.email)) {
			return Promise.reject('Invalid email address format');
		}
		if (data['businessLisence']) {
			businessLisence = data['businessLisence'];
		}
		if (data['alcoholLicense']) {
			alcoholLicense = data['alcoholLicense'];
		}
		delete data['businessLisence'];
		delete data['alcoholLicense'];

		const formData = new FormData();
		formData.append(
			'userDtoRequest',
			new Blob([JSON.stringify(data)], { type: 'application/json' })
		);
		if (businessLisence !== null) {
			formData.append('businessLisence', businessLisence);
		}
		if (alcoholLicense !== null) {
			formData.append('alcoholLicense', alcoholLicense);
		}
		try {
			const response = await axios.post(
				`${baseURL}/auth/registration`,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				}
			);
			this.sendVerificationEmail(data.email);
			return Promise.resolve(response);
		} catch ({ response }) {
			switch (response.status) {
				case 400:
					return Promise.reject('This email is already taken');
				case 500:
					return Promise.reject('Internal server error');
				default:
					return Promise.reject('An error has occured');
			}
		}
	}

	static validateEmail(email) {
		const regex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(email);
	}

	static async checkLogin(login) {
		const baseURL = Constants.API_BASE_URL;
		const response = await axios.post(
			`${baseURL}/auth/check-login?login=${login}`
		);
		return Promise.resolve(response);
	}

	static async checkToken() {
		const baseURL = Constants.API_BASE_URL;
		const response = await authAxios.get(`${baseURL}/auth/check-login`);
		return Promise.resolve(response);
	}

	static setCredentials(response) {
		const encryptedRole = AESEncrypt(response.data.role.roleName);
		response.data.role.roleName = encryptedRole;

		var baseRole = { roleName: response.data.role.roleName };
		response.data.baseRole = baseRole.roleName;

		localStorage.setItem('user', JSON.stringify(response.data));
		localStorage.setItem('token', response.headers['jwt-token']);
	}

	static async resetPassword(data) {
		const baseURL = Constants.API_BASE_URL;
		return await axios.post(
			`${baseURL}/account-info/reset-password-send-email`,
			data
		);
	}

	static async sendVerificationEmail(email) {
		const baseURL = Constants.API_BASE_URL;
		return await axios.post(`${baseURL}/auth/send-verification-email`, {
			email
		});
	}

	static logout() {
		localStorage.removeItem('user');
		localStorage.removeItem('token');

		window.location = '/login';
	}

	static isLoggedIn() {
		if (!!localStorage.getItem('user') && !!localStorage.getItem('token')) {
			authAxios
				.get('/auth/check-token')
				.then(response => {
					if (!response.data) {
						localStorage.removeItem('user');
						localStorage.removeItem('token');
					}
				})
				.catch(() => {
					localStorage.removeItem('user');
					localStorage.removeItem('token');
				});
			return true;
		} else {
			return false;
		}
	}

	static getCurrentUser() {
		return JSON.parse(localStorage.getItem('user'));
	}
}
export default AuthService;
