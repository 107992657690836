import React from 'react'
import { Grid } from '@mui/material'


export const Sidebar = (props) => {

  return (
    <>
      <Grid item xs={3} >
      {props.children}
      </Grid>
    </>
  )
}
