import React, { useEffect, useState } from 'react';
import './providersList.scss';
import { useParams, Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CatalogService from '../../../services/CatalogService';
import { ContentHeader } from '../../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../../common/helpers/HeadMenu/HeadMenu';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';

export const ProvidersList = props => {
	const [providers, setProviders] = useState([]);
	const [category, setCategory] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	let { catId } = useParams();
	var cityId = localStorage.getItem('cityId');

	const location = useLocation();

	useEffect(() => {
		if (localStorage.getItem('cityId') !== null) {
			setIsLoading(true);
			CatalogService.getProviderByCategoryId({ catId, cityId })
				.then(response => {
					setProviders(response.data);
				})
				.finally(() => {
					setIsLoading(false);
				});
			setCategory(location.state.category);
		}
	}, [catId, location]);

	const sidebarContent = () => {
		return (
			<>
				<h3>Категории</h3>
			</>
		);
	};

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme sidebar={sidebarContent()}>
				<h1>{category?.nameRu}</h1>
				<Breadcrumbs
					className='breadcrumbs'
					aria-label='breadcrumb'
					sx={{ color: 'rgba(0,0,0,.20)' }}
				>
					<Link underline='hover' color='inherit' to='/'>
						Главная
					</Link>
					<Link underline='hover' color='inherit' to='/providers-categories'>
						Фирмы
					</Link>
				</Breadcrumbs>
				<div className='provider-card'>
					{isLoading ? (
						<>
							<div className='loading-block'>
								<CircularProgress />
							</div>
						</>
					) : (
						<>
							{providers?.map((provider, i) => (
								<Link
									to={'/provider/' + provider.id}
									state={{ category: category }}
									key={i}
								>
									<div className='category' key={i}>
										<div className='about'>
											<img src={provider.logotype[0]?.url} alt='' />
										</div>
										<div className='desc'>
											<h4>{provider.name}</h4>
											<p>БИН: {provider.businessNumber}</p>
											<p>Тел: {provider.phone}</p>
											<p>Адрес: {provider.address}</p>
										</div>
									</div>
								</Link>
							))}
							{providers?.length === 0
								? 'В этом городе пока нет поставщиков и компаний!'
								: null}
						</>
					)}
				</div>
			</ContentScheme>
		</>
	);
};
