import React from "react";
import "./stockLayer.scss";
import discountLogo from "../../../../assets/images/discount.svg";

export const StockLayer = ({ children, icon = false }) => {
  return (
    <>
      <div className="stocks-layer">
        <span>
          {" "}
          {children} <img src={discountLogo} alt="" />
        </span>
      </div>
    </>
  );
};
