import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
	cartItems: localStorage.getItem('Cart Items')
		? JSON.parse(localStorage.getItem('Cart Items'))
		: [],
	cartTotalQuantity: localStorage.getItem('cartTotalQuantity')
		? JSON.parse(localStorage.getItem('cartTotalQuantity'))
		: 0,
	cartTotalAmount: localStorage.getItem('cartTotalAmount')
		? JSON.parse(localStorage.getItem('cartTotalAmount'))
		: 0,
	priceWithoutDiscount: localStorage.getItem('priceWithoutDiscount')
		? JSON.parse(localStorage.getItem('priceWithoutDiscount'))
		: 0
};

const cartSlice = createSlice({
	name: 'Cart',
	initialState,
	reducers: {
		addToCart(state, action) {
			// const itemIndex = state.cartItems.findIndex(
			//   (item) => item.id === action.payload.id
			// );

			const itemIndex = state.cartItems.findIndex(
				item => item.id === action.payload.provider.id
			);

			if (itemIndex >= 0) {
				const goodIndex = state.cartItems[itemIndex].goods.findIndex(
					item => item.id === action.payload.id
				);

				if (goodIndex >= 0) {
					state.cartItems[itemIndex].goods[goodIndex].cartQuantity += 1;
					state.cartItems[itemIndex].cartQuantity += 1;
					if (state.cartItems[itemIndex].goods[goodIndex].discount) {
						state.cartItems[itemIndex].cartTotalAmount +=
							state.cartItems[itemIndex].goods[goodIndex].discountprice;
						state.cartItems[itemIndex].totalDiscount +=
							state.cartItems[itemIndex].goods[goodIndex].discount;
						state.cartItems[itemIndex].priceWithoutDiscount +=
							state.cartItems[itemIndex].goods[goodIndex].price;
					} else {
						state.cartItems[itemIndex].cartTotalAmount +=
							state.cartItems[itemIndex].goods[goodIndex].price;
						state.cartItems[itemIndex].priceWithoutDiscount +=
							state.cartItems[itemIndex].goods[goodIndex].price;
						state.cartItems[itemIndex].totalDiscount += 0;
					}
				} else {
					const tempGood = { ...action.payload, cartQuantity: 1 };

					// if(action.payload.discount != null){
					//   tempGood.priceWithoutStock = action.payload.price;
					//   tempGood.price = action.payload.price - ((action.payload.price*action.payload.discount) / 100)
					// }
					state.cartItems[itemIndex].cartQuantity += 1;
					if (tempGood.discount) {
						state.cartItems[itemIndex].cartTotalAmount +=
							tempGood.discountprice;
						state.cartItems[itemIndex].priceWithoutDiscount += tempGood.price;
						state.cartItems[itemIndex].totalDiscount += tempGood.discount;
					} else {
						state.cartItems[itemIndex].cartTotalAmount += tempGood.price;
						state.cartItems[itemIndex].priceWithoutDiscount += tempGood.price;
						state.cartItems[itemIndex].totalDiscount += 0;
					}

					state.cartItems[itemIndex].goods.push(tempGood);
					toast.success(`${action.payload.title} добавлен в корзину!`, {
						position: 'bottom-left'
					});
				}
			} else {
				const tempGood = { ...action.payload, cartQuantity: 1 };
				let totalAmount = 0;
				let priceWithoutDiscount = 0;
				let totalDiscount = 0;
				if (action.payload.discount) {
					totalAmount = action.payload.discountprice;
					priceWithoutDiscount = action.payload.price;
					totalDiscount = action.payload.discount;
				} else {
					totalAmount = action.payload.price;
					priceWithoutDiscount = action.payload.price;
					totalDiscount = action.payload.discount;
				}
				const tempProvider = {
					...action.payload.provider,
					goods: [tempGood],
					cartQuantity: 1,
					cartTotalAmount: totalAmount,
					priceWithoutDiscount: priceWithoutDiscount,
					totalDiscount: totalDiscount
				};

				state.cartItems.push(tempProvider);
			}

			//   if(action.payload.discount != null){
			//     tempGood.priceWithoutStock = action.payload.price;
			//     tempGood.price = action.payload.price - ((action.payload.price*action.payload.discount) / 100)
			//   }

			localStorage.setItem('Cart Items', JSON.stringify(state.cartItems));
			localStorage.setItem(
				'cartTotalQuantity',
				JSON.stringify(state.cartTotalQuantity)
			);
			localStorage.setItem(
				'cartTotalAmount',
				JSON.stringify(state.cartTotalAmount)
			);
		},

		removeFromCart(state, action) {
			state.cartItems.forEach((element, i) => {
				const nextCartItems = element.goods.filter(
					item => item.id !== action.payload.id
				);
				let quantity = 0;
				let amount = 0;
				let priceWithoutDiscount = 0;
				nextCartItems.forEach(good => {
					quantity = quantity + good.cartQuantity;
					priceWithoutDiscount = amount + good.price * good.cartQuantity;
					if (good.discount) {
						amount = amount + good.discountprice * good.cartQuantity;
					} else {
						amount = amount + good.price * good.cartQuantity;
					}
				});

				element.cartQuantity = quantity;
				element.cartTotalAmount = amount;
				element.priceWithoutDiscount = priceWithoutDiscount;

				element.goods = nextCartItems;

				if (element.cartQuantity == 0) {
					state.cartItems.splice(i, 1);
				}
			});

			toast.error('Товар удален из корзины!', {
				position: 'bottom-left'
			});

			localStorage.setItem('Cart Items', JSON.stringify(state.cartItems));
		},

		minusQauntity(state, action) {
			state.cartItems.forEach((element, i) => {
				const itemIndex = element.goods.findIndex(
					item => item.id === action.payload.id
				);

				if (itemIndex != -1) {
					if (element.goods[itemIndex].cartQuantity > 1) {
						element.goods[itemIndex].cartQuantity -= 1;
						if (element.goods[itemIndex].discount) {
							element.cartTotalAmount -= element.goods[itemIndex].discountprice;
							element.priceWithoutDiscount -= element.goods[itemIndex].price;
						} else {
							element.cartTotalAmount -= element.goods[itemIndex].price;
							element.priceWithoutDiscount -= element.goods[itemIndex].price;
						}
					} else if (element.goods[itemIndex].cartQuantity <= 1) {
						if (element.goods[itemIndex].discount) {
							element.cartTotalAmount -= element.goods[itemIndex].discountprice;
							element.priceWithoutDiscount -= element.goods[itemIndex].price;
						} else {
							element.cartTotalAmount -= element.goods[itemIndex].price;
							element.priceWithoutDiscount -= element.goods[itemIndex].price;
						}
						element.goods.splice(itemIndex, 1);
						toast.error('Товар удален из корзины!', {
							position: 'bottom-left'
						});
					}

					element.cartQuantity -= 1;
				}
				if (element.cartQuantity == 0) {
					state.cartItems.splice(i, 1);
				}
			});
			localStorage.setItem('Cart Items', JSON.stringify(state.cartItems));
		},

		getTotals(state, action) {
			let { total, quantity, priceWithoutDiscount } = state.cartItems.reduce(
				(cartTotal, cartItem) => {
					const { cartTotalAmount, cartQuantity, priceWithoutDiscount } =
						cartItem;
					// const itemTotal = cartTotalAmount * cartQuantity;

					cartTotal.total += cartTotalAmount;
					cartTotal.quantity += cartQuantity;
					cartTotal.priceWithoutDiscount += priceWithoutDiscount;
					return cartTotal;
				},
				{
					total: 0,
					priceWithoutDiscount: 0,
					quantity: 0
				}
			);

			state.cartTotalAmount = total;
			state.cartTotalQuantity = quantity;
			state.priceWithoutDiscount = priceWithoutDiscount;

			localStorage.setItem(
				'cartTotalQuantity',
				JSON.stringify(state.cartTotalQuantity)
			);
			localStorage.setItem(
				'cartTotalAmount',
				JSON.stringify(state.cartTotalAmount)
			);
			localStorage.setItem(
				'priceWithoutDiscount',
				JSON.stringify(state.priceWithoutDiscount)
			);
		}
	}
});

export const { addToCart, removeFromCart, minusQauntity, getTotals } =
	cartSlice.actions;

export default cartSlice.reducer;
