export const buttonStyles = {
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '18',
  padding: '6px 15px',
  border: '1px solid',
  lineHeight: '1.5',
  borderColor: '#D9D9D9',
  borderRadius: '20px',
  color: 'black',
  fontFamily: "Roboto Condensed",
  fontWeight: "400",
  transition: '.25s',
  '&:hover':{
    backgroundColor: '#FCA522',
    boxShadow: 'none',      
    border: '1px solid #FCA522',
    color: 'white',
    '.MuiSvgIcon-root': {
      color: '#fff',
      transition: '.25s',
    }
  },
  '&:active': {
    boxShadow: 'none',
    color:'white',
  }
}