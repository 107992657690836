export const menu = [
	{
		title: 'Акции',
		link: '/promotions'
	},
	{
		title: 'Фирмы',
		link: '/providers-categories'
	},
	{
		title: 'Контакты',
		link: '/contacts'
	}
];

export const headBackground = 'images/background/clients.png';

export const head = {
	title: 'Торговая площадка',
	desc: 'Saudaline.kz занимается услугой продажи',
	btn1: 'Торговым точкам (магазин)',
	link1: '/to-customers',
	btn2: 'Поставщикам (фирма)',
	link2: '/to-providers'
};

export const contacts = {
	title: 'Контакты',
	phone: '+7 707 695 5497',
	email: 'saudaline.kz@mail.ru',
	address: 'Толе би 190'
};

export const formdata = {
	email: 'e-mail',
	textarea: 'Обрашение',
	btn: 'Отправить сообщение'
};

export const socialLinks = {
	instagram: 'instagram.com/userlink',
	facebook: 'facebook.com/userlink',
	twitter: 'twitter.com/userlink'
};

export const benefits = [
	{
		title: 'Удобный',
		desc: 'Быстрый и удобный поиск позволяющий при минимальных затратах времени получить максимальный результат'
	},
	{
		title: 'Удобный',
		desc: 'Быстрый и удобный поиск позволяющий при минимальных затратах времени получить максимальный результат'
	},
	{
		title: 'Удобный',
		desc: 'Быстрый и удобный поиск позволяющий при минимальных затратах времени получить максимальный результат'
	},
	{
		title: 'Удобный',
		desc: 'Быстрый и удобный поиск позволяющий при минимальных затратах времени получить максимальный результат'
	},
	{
		title: 'Удобный',
		desc: 'Быстрый и удобный поиск позволяющий при минимальных затратах времени получить максимальный результат'
	}
];

export const aboutCompany = {
	title: 'О компаний',
	desc: 'Торговая площадка, которая дает возможность ВЛАДЕЛЬЦАМ МАГАЗИНОВ бесплатный доступ к базе прямых поставщиков с ценами,  ДИСТРИБЬЮТОРАМ возможность получать прямые заказы с торговых точек в режиме online. Торговая площадка, которая дает возможность ВЛАДЕЛЬЦАМ МАГАЗИНОВ бесплатный доступ к базе прямых поставщиков с ценами,  ДИСТРИБЬЮТОРАМ возможность получать прямые заказы с торговых точек в режиме online.',
	link: '/about',
	linkTitle: 'Подробнее'
};

export const slides = [
	{
		title: 'Шедрый шопинг №1',
		subTitle: 'Акция Джин: #ЖИВИВКУСНО',
		desc: 'Летняя акция от семечек "Джинн" Подарки - эксклюзивная тарелочка для семечек, набор ярких термосалфеток, фирменная кружка и конечно любимые семечки "Джинн"! Никаких конкурсов и промокодов! Загрузи фотографию на сайте мероприятия, оформи в фоторедакторе и поделись с друзьями в социальных сетях.',
		img: 'img.jpg',
		stock: true,
		stockTitle: 'Акция до 20'
	},
	{
		title: 'Шедрый шопинг №2',
		subTitle: 'Акция Джин: #ЖИВИВКУСНО',
		desc: 'Летняя акция от семечек "Джинн" Подарки - эксклюзивная тарелочка для семечек, набор ярких термосалфеток, фирменная кружка и конечно любимые семечки "Джинн"! Никаких конкурсов и промокодов! Загрузи фотографию на сайте мероприятия, оформи в фоторедакторе и поделись с друзьями в социальных сетях.',
		img: 'img.jpg',
		stock: false
	},
	{
		title: 'Шедрый шопинг №3',
		subTitle: 'Акция Джин: #ЖИВИВКУСНО',
		desc: 'Летняя акция от семечек "Джинн" Подарки - эксклюзивная тарелочка для семечек, набор ярких термосалфеток, фирменная кружка и конечно любимые семечки "Джинн"! Никаких конкурсов и промокодов! Загрузи фотографию на сайте мероприятия, оформи в фоторедакторе и поделись с друзьями в социальных сетях.',
		img: 'img.jpg',
		stock: true,
		stockTitle: 'Акция до 20'
	}
];

export const partners = [
	{
		title: 'Ainaline',
		logo: 'images/partners/ainaline.png',
		link: 'partners/ainaline'
	},
	{
		title: 'Coca-Cola',
		logo: 'images/partners/coca-cola.png',
		link: 'partners/ainaline'
	},
	{
		title: 'Bayan-Sulu',
		logo: 'images/partners/bayan.png',
		link: 'partners/bayan'
	},
	{
		title: 'Flint',
		logo: 'images/partners/flint.png',
		link: 'partners/flint'
	},
	{
		title: 'Ainaline',
		logo: 'images/partners/ainaline.png',
		link: 'partners/ainaline'
	},
	{
		title: 'Coca-Cola',
		logo: 'images/partners/coca-cola.png',
		link: 'partners/ainaline'
	},
	{
		title: 'Bayan-Sulu',
		logo: 'images/partners/bayan.png',
		link: 'partners/bayan'
	},
	{
		title: 'Flint',
		logo: 'images/partners/flint.png',
		link: 'partners/flint'
	}
];

export const partnerBtn = {
	title: 'Поставщики',
	label: 'СПИСОК',
	link: '/partners'
};

export const clients = {
	text: 'КЛИЕНТЫ',
	label: 'СПИСОК',
	img: 'images/background/clients.png',
	link: '/clients'
};

export const sidebarAdvs = [
	{
		img: 'images/advs/img-adv.png',
		link: '/advert01'
	},
	{
		img: 'images/advs/img-adv-01.png',
		link: '/advert02'
	}
];

export const category = [
	{
		id: '1',
		title: 'Фирмы, поставляющие продукты питания',
		img: 'images/category/cat-1.png'
	},
	{
		id: '2',
		title: 'Фирмы, поставляющие товары бытовой химии',
		img: 'images/category/cat-2.png'
	},
	{
		id: '3',
		title: 'Фирмы, поставляющие табачную и алкогольную продукцию',
		img: 'images/category/cat-3.png'
	}
];

export const company = [
	{
		id: '1',
		cat_id: '1',
		name: 'ТОО "Сабыржан"',
		logo: 'provider-1.jpg',
		desc: 'Шоколад, Безалкогольные напитки, Компот, Квас, Энергетические напитки, Вода, Сок'
	},
	{
		id: '2',
		cat_id: '1',
		name: 'ТОО "Бакалей сервис"',
		logo: 'provider-2.jpg',
		desc: 'Новогодние подарки, Печенье, Вафли, Карамель, Ирис, Драже, Конфеты',
		brands: 'Рахат'
	},
	{
		id: '3',
		cat_id: '1',
		name: 'ТОО "Riks"',
		logo: 'provider-3.jpg',
		desc: 'Энергетические напитки, Коктейли, Лимонад, Газированные напитки, Вода, Сок, Холодный чай',
		brands: 'DIZZY, BUZZ, FEST BERRY, ЗЛАТОЯР, SMILE, АСЕМ-АЙ, ARZU'
	},
	{
		id: '4',
		cat_id: '2',
		name: 'Салем',
		logo: 'provider-4.jpg',
		desc: 'Сливки сухие, Канцелярия, Блокноты, Альбомы, Бумага, Ватманы, Бейджи',
		brands: 'Hatber, Mondi, Lomond, Стамм'
	},
	{
		id: '5',
		cat_id: '2',
		name: 'ТОО "Аврора Брендс"',
		logo: 'provider-5.jpg',
		desc: 'Шампунь, Бальзам-ополаскиватель для волос, Гель для душа, Мыло, Пена для ванн, Средства для мытья посуды, Средства для кухни',
		brands: 'AVRORA COSMETICS, ЧИСТАЯ КАСТРЮЛЬКА, EFFECT, Akmasept'
	},
	{
		id: '6',
		cat_id: '2',
		name: 'ТОО "Trade & Distribution Бытовая химия"',
		logo: 'provider-6.jpg',
		desc: 'Другие средства по уходу за волосами, Бальзам-ополаскиватель для волос, Маска для волос, Шампунь, Наборы для ухода за волосами, Мыло, Гигиенические прокладки',
		brands:
			'Pantene, Head & Shoulders, Wash & Go, Safeguard, Always, Naturella, Discreet'
	},
	{
		id: '7',
		cat_id: '3',
		name: 'ТОО "Сабыржан"',
		logo: 'provider-1.jpg',
		desc: 'Шоколад, Безалкогольные напитки, Компот, Квас, Энергетические напитки, Вода, Сок'
	},
	{
		id: '8',
		cat_id: '3',
		name: 'ТОО "Caspian Beverage Holding"',
		logo: 'provider-6.jpg',
		desc: 'Пиво, Газированные напитки, Сокосодержащие напитки, Энергетические напитки, Безалкогольные коктейли'
	}
];

export const goods = [
	{
		id: '1',
		providerId: '1',
		title: 'Tobot Z трансформер №1',
		price: '1600',
		discount: '10',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	},
	{
		id: '2',
		providerId: '1',
		title: 'Tobot Z трансформер №2',
		price: '1600',
		discount: '10',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	},
	{
		id: '3',
		providerId: '1',
		title: 'Tobot Z трансформер №3',
		price: '1600',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	},
	{
		id: '4',
		providerId: '1',
		title: 'Tobot Z трансформер №4',
		price: '1600',
		discount: '10',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	},
	{
		id: '5',
		providerId: '1',
		title: 'Tobot Z трансформер №5',
		price: '1600',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	},
	{
		id: '6',
		providerId: '1',
		title: 'Tobot Z трансформер №6',
		price: '1600',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	},
	{
		id: '7',
		providerId: '1',
		title: 'Tobot Z трансформер №7',
		price: '1600',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	},
	{
		id: '8',
		providerId: '1',
		title: 'Tobot Z трансформер №7',
		price: '1600',
		discount: '10',
		img: 'images/goods/good-1.png',
		short_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать.',
		full_desc:
			'Парагон - Тобот из 2-го сезона мультсериала "Детективы Галактики"Тобот Парагон считает себя почтенным рыцарем, а трансформируется в классический автомобиль 30-х годов прошлого века, что характеризует его благородство, доблесть и стать. Точно как в мультфильме!Мини-формат Тобота-трансформера удобно брать с собой, он не займет много места и будет постоянно радовать ребенка. Процесс трансформации мини-тобота немного упрощенный по сравнению с полноразмерной версией и понятнее для детей младшего возраста.Высокое сходство игрушки с одноименным персонажем из мультфильма делает игру еще более увлекательной и развивает фантазию ребенка.Формат игрушки-трансформера на протяжении многих лет является одним из наиболее популярных среди детей 4-7 лет, а в комбинации с образом любимого героя игрушка может стать самой любимой на несколько лет.В наборе с игрушкой идет красочная инструкция в картинках, с помощью которой будет легко разобраться в трансформации Тобота.',
		specification: {
			'Вес товара с упаковкой (г)': '130г.',
			'Высота предмета': '12 см',
			'Глубина предмета': '6 см',
			'Ширина предмета': '9 см',
			'Материал игрушки': 'Пластик (ABC)',
			'Интерактивные функции': 'Трансформация робота в машину',
			'Страна производства': 'Китай',
			'Любимые герои': 'TOBOT',
			Эффекты: 'трансформация',
			'Особенности игрушки':
				'Высокое качество; лицензионный продукт; персонаж популярного мультфильма',
			'Возрастные ограничения': '4+'
		}
	}
];

export const cities = [
	{
		id: '1',
		name: 'Астана'
	},
	{
		id: '2',
		name: 'Алматы'
	},
	{
		id: '3',
		name: 'Тараз'
	},
	{
		id: '4',
		name: 'Шымкент'
	},
	{
		id: '5',
		name: 'Талдыкорган'
	},
	{
		id: '6',
		name: 'Караганды'
	},
	{
		id: '7',
		name: 'Актау'
	},
	{
		id: '8',
		name: 'Кызылорда'
	},
	{
		id: '9',
		name: 'Қонаев'
	}
];
