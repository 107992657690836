import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { Sidebar } from '../sidebar/Sidebar'

export const ContentScheme = ({sidebar=null, children}) => {

  return (
    <>
    <Container>
      <Grid container spacing={1}>
        {sidebar ?
        <Sidebar>
          {sidebar}
        </Sidebar>
          : null}
        
        <Grid item xs={sidebar ? 9 : 12} >
            {children}
        </Grid>
      </Grid>
    </Container>
    </>
  )
}
