import React, { useEffect, useState } from 'react';
import { ContentHeader } from '../../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../../common/helpers/HeadMenu/HeadMenu';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CatalogService from '../../../services/CatalogService';
import withServices from '../../../hocs/withServices';
// import { category as categories } from '../../common/assets/data';
import './providersCategories.scss';
import Adverts from '../../Adverts';

function ProvidersCategories({ services }) {
	const [category, setCategory] = useState([]);

	useEffect(() => {
		CatalogService.getAllProviderCategories().then(response => {
			setCategory(response.data);
		});
	}, []);

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme sidebar={Adverts()}>
				<h1>Фирмы</h1>
				<Breadcrumbs
					className='breadcrumbs'
					aria-label='breadcrumb'
					sx={{ color: 'rgba(0,0,0,.20)' }}
				>
					<Link underline='hover' color='inherit' to='/'>
						Главная
					</Link>
					<Typography color='text.primary'>Фирмы</Typography>
				</Breadcrumbs>
				<div className='category-card'>
					{category.map((cat, i) => (
						<div className='category' key={i}>
							<Link
								to={'/providers-categories/' + cat.id + '/providers'}
								state={{ category: cat }}
							>
								<img src={cat.photo[0].url} alt='' />
								<p>{cat.nameRu}</p>
							</Link>
						</div>
					))}
				</div>
			</ContentScheme>
		</>
	);
}

export default withServices()(ProvidersCategories);
