import React, { Children } from "react";
import "./heading.scss";

export const Heading = ({
  title,
  width = 150,
  align = "center",
  fontSize = "16px",
  justifyContent = "center",
  children,
}) => {
  return (
    <>
      <div
        className="heading"
        style={{
          justifyContent: justifyContent,
        }}
      >
        <div
          className="h1"
          style={{
            width: width,
            textAlign: align,
            justifyContent: justifyContent,
          }}
        >
          <h1
            style={{
              fontSize: fontSize,
            }}
          >
            {title}
          </h1>
          {children}
        </div>
      </div>
    </>
  );
};
