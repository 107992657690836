import React from 'react';
import { Footer } from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import AuthenticatedContent from '../../common/AuthenticatedContent';

function PublicPages(props) {
	return (
		<>
			<Header />
			<div style={{ paddingBottom: '370px' }}>{props.children}</div>
			<Footer />
		</>
	);
}

export default PublicPages;
