import React from 'react';
import './content-header.scss';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import logo from '../../assets/images/logo-black.svg';
import {CardButton} from '../../helpers/cardButton/CardButton'
import { Link } from 'react-router-dom';

export const ContentHeader = () => {
  return (
    <>
    <Container>
      <Grid container 
            direction="row"
            justifyContent="center"
            alignItems="center">
        <Grid item xs={6} md={6} align="left">
          <Link to='/'>
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          </Link>
        </Grid>
        <Grid item xs={6} md={6} align="right" sx={{marginTop:"50px", marginBottom:"50px"}}>
          <CardButton />
        </Grid>
      </Grid>
    </Container>
    </>
  )
}
