import React, { useEffect, useState } from 'react';
import './provider.scss';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../store/slices/Cart/cartSlice';
import goodsService from '../../../services/goodsService';
import CircularProgress from '@mui/material/CircularProgress';
import CatalogService from '../../../services/CatalogService';
import { ContentHeader } from '../../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../../common/helpers/HeadMenu/HeadMenu';
import { useLocation } from 'react-router-dom';
import Constants from '../../../common/constants';
import { StocksSign } from '../../../common/helpers/main/Stocks/StocksSign/StocksSign';
import { Breadcrumbs, Grid, Pagination, Divider } from '@mui/material';

export const Provider = props => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingGoods, setIsLoadingGoods] = useState(false);
	const pageSize = 16;
	const [provider, setProvider] = useState();
	const [category, setCategory] = useState({});
	const [providerCategories, setProviderCategories] = useState();
	const [goods, setGoods] = useState([]);
	let { providerId } = useParams();
	const [pagination, setPagination] = useState({
		count: 0,
		from: 0,
		to: pageSize
	});

	const [sort, setSort] = useState('');
	const handleChange = event => {
		setSort(event.target.value);
	};

	const location = useLocation();

	useEffect(() => {
		setIsLoading(true);
		CatalogService.getProviderById({ providerId })
			.then(response => {
				setProvider(response.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
		setIsLoading(true);

		CatalogService.getCategoriesByProviderId({ providerId })
			.then(response => {
				setProviderCategories(response.data);
			})
			.finally(() => {
				setIsLoading(false);
			});

		setCategory(location.state.category);
	}, [providerId, location]);

	useEffect(() => {
		setIsLoadingGoods(true);
		goodsService
			.getGoodsByProviderId({
				from: pagination.from,
				to: pagination.to,
				providerId: providerId
			})
			.then(response => {
				setPagination({
					...pagination,
					count: response.headers['x-total-count']
				});
				setGoods(response.data);
			})
			.finally(() => {
				setIsLoadingGoods(false);
			});
	}, [providerId, pagination.from, pagination.to]);

	const handleAddToCart = (e, good) => {
		e.preventDefault();
		dispatch(addToCart(good));
	};

	const handlePageChange = (event, page) => {
		const from = (page - 1) * pageSize;
		const to = (page - 1) * pageSize + pageSize;

		setPagination({ ...pagination, from: from, to: to });
	};

	const sidebarContent = () => {
		return (
			<>
				<h3>Категории</h3>
			</>
		);
	};

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme sidebar={sidebarContent()}>
				{/* <h1>{provider.name}</h1> */}
				<Breadcrumbs
					className='breadcrumbs'
					aria-label='breadcrumb'
					sx={{ color: 'rgba(0,0,0,.20)' }}
				>
					<Link underline='hover' color='inherit' to='/'>
						Главная
					</Link>
					<Link underline='hover' color='inherit' to='/providers-categories'>
						Фирмы
					</Link>
					<Link
						underline='hover'
						color='inherit'
						to={'/providers-categories/' + category?.id + '/providers'}
						state={{ category: category }}
					>
						{category?.nameRu}
					</Link>
				</Breadcrumbs>
				{isLoading ? (
					<>
						<div className='loading-block'>
							<CircularProgress />
						</div>
					</>
				) : (
					<>
						<div className='about-provider'>
							<div className='provider' key={provider?.id}>
								<div className='about'>
									<img src={provider?.logotype[0]?.url} alt='' />
								</div>
								<div className='desc'>
									<h4>{provider?.name + ' ' + provider?.businessType?.name}</h4>
									<p>БИН: {provider?.businessNumber}</p>
									<p>Тел: {provider?.phone}</p>
									<p>Адрес: {provider?.address}</p>
								</div>
							</div>
						</div>
						<Divider />
						<div className='providers-categories'>
							<div className='desc'>
								<h3>Продукция поставщика</h3>
							</div>
							<div className='categories'>
								{providerCategories?.map((category, i) => {
									if (i % 3 === 0) {
										return (
											<div className='category-container' key={i}>
												<div className='category'>
													<Link to={'/products/' + providerCategories[i].id}>
														<p>{providerCategories[i].title}</p>
													</Link>
												</div>
												{i + 1 < providerCategories.length && (
													<div className='category'>
														<Link
															to={'/products/' + providerCategories[i + 1].id}
														>
															<p>{providerCategories[i + 1].title}</p>
														</Link>
													</div>
												)}
												{i + 2 < providerCategories.length && (
													<div className='category'>
														<Link
															to={'/products/' + providerCategories[i + 2].id}
														>
															<p>{providerCategories[i + 2].title}</p>
														</Link>
													</div>
												)}
											</div>
										);
									} else {
										return null;
									}
									// return (
									// 	<div className='category' key={i}>
									// 		<Link to={'/products/' + category.id}>
									// 			<p>{category.title}</p>
									// 		</Link>
									// 	</div>
									// );
								})}
							</div>
						</div>
					</>
				)}
				<Divider />
				{isLoadingGoods ? (
					<>
						<div className='loading-block'>
							<CircularProgress />
						</div>
					</>
				) : (
					<>
						<div className='provider-products'>
							<div className='providers-categories'>
								<div className='desc'>
									<h3>Товары поставщика</h3>
								</div>
							</div>
							{/* <Grid
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							m: '20px 0 10px 0'
						}}
					>
						<Grid
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								padding: '8px'
							}}
						>
							<FormControl
								sx={{ minWidth: 200, borderRadius: '50px' }}
								size='small'
							>
								<InputLabel id='demo-select-small'>Сортировка</InputLabel>
								<Select
									labelId='demo-select-small'
									id='demo-select-small'
									value={sort}
									label='Сортировка'
									onChange={handleChange}
									sx={{ borderRadius: '50px', backgroundColor: '#D9D9D978' }}
								>
									<MenuItem value=''>
										<em>Нету</em>
									</MenuItem>
									<MenuItem value='popularity'>По популярности</MenuItem>
									<MenuItem value='price'>По цене</MenuItem>
								</Select>
							</FormControl>
							<ButtonGroup
								disableElevation
								variant='contained'
								aria-label='Disabled elevation buttons'
								sx={{ ml: 2, height: '40px' }}
							>
								<Button sx={{ borderRadius: '50px' }}>
									<AppsIcon />
								</Button>
								<Button sx={{ borderRadius: '50px' }}>
									<MenuIcon />
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center'
							}}
						>
							<Typography>
								Товары {goods.length} из {pagination.count}
							</Typography>
						</Grid>
					</Grid> */}
							<Grid className='goods-block' sx={{ marginTop: '20px' }}>
								{goods?.map(good => (
									<Link
										to={
											'/provider/' +
											providerId +
											'/category/' +
											good?.category?.id +
											'/good/' +
											good?.id
										}
										key={good?.id}
									>
										<div className='good'>
											<img
												src={
													good?.mainPhoto?.url
														? good?.mainPhoto?.url
														: `${Constants.BASE_URL}static/media/logo-black.2496a132b014dd3006c66cb13eebd443.svg`
												}
												alt='good'
											/>
											<div className='stock-sign-con'>
												{good?.discount ? (
													<div className='stock-sign'>
														<StocksSign>{good?.discount}</StocksSign>
													</div>
												) : null}
											</div>
											<h4>{good?.title}</h4>
											<p>
												{good?.discount ? (
													<>
														<span className='old-price'>{good?.price} ₸</span>

														<span> {good?.discountprice} ₸</span>
													</>
												) : (
													<>
														<span>{good?.price} ₸</span>
													</>
												)}
											</p>
											<button onClick={e => handleAddToCart(e, good)}>
												Добавить в корзину
											</button>
										</div>
									</Link>
								))}
							</Grid>
							<Grid
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '20px',
									marginTop: '20px'
								}}
							>
								<Pagination
									count={Math.ceil(pagination.count / pageSize)}
									onChange={handlePageChange}
								/>
							</Grid>
						</div>
					</>
				)}
			</ContentScheme>
		</>
	);
};
