import React from 'react'
import './StocksSign.scss'

export const StocksSign = ({children}) => {
  return (
    <>
      <div className="stock-sign">
        {children}%
      </div>
    </>
  )
}
