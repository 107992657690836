import axios from 'axios';
import Constants from '../common/constants';

const CatalogService = {
	getAllCities: () => {
		return axios.get(`${Constants.API_BASE_URL}/city/get-all-cities`);
	},

	getCitiesByProductsCount: () => {
		return axios.get(
			`${Constants.API_BASE_URL}/city/get-cities-by-products-count`
		);
	},

	getCityByName: cityName => {
		if (cityName)
			return axios.get(
				`${Constants.API_BASE_URL}/city/get-by-city-name?cityName=${cityName}`
			);
		else return axios.get(`${Constants.API_BASE_URL}/city/get-by-city-name`);
	},

	getCitiesByRegion: ({ regionId }) => {
		const response = axios.get(
			`${Constants.API_BASE_URL}/city/get-all-cities?regionId=${regionId}`
		);
		return Promise.resolve(response);
	},

	getAllRegion: () => {
		return axios.get(`${Constants.API_BASE_URL}/region`);
	},

	getBusinessTypes: () => {
		return axios.get(
			`${Constants.API_BASE_URL}/business-types/get-all-business-types`
		);
	},

	getAllProviderCategories: () => {
		return axios.get(
			`${Constants.API_BASE_URL}/provider-category/get-all-provider-categories`
		);
	},

	getProviderByCategoryId: ({ catId, cityId }) => {
		return axios.get(
			`${Constants.API_BASE_URL}/provider/get-provider-by-category-id?providerCatId=${catId}&cityId=${cityId}`
		);
	},

	getProviderById: ({ providerId }) => {
		return axios.get(
			`${Constants.API_BASE_URL}/provider/get-by-id?providerId=${providerId}`
		);
	},

	getCategoriesByUserId: ({ userId }) => {
		return axios.get(`${Constants.API_BASE_URL}/categories?userId=${userId}`);
	},

	getCategoriesByProviderId: ({ providerId }) => {
		return axios.get(
			`${Constants.API_BASE_URL}/categories/get-by-provider-id?providerId=${providerId}`
		);
	}
};

export default CatalogService;
