import React, { useEffect, useState } from 'react';
import { Grid, Typography, Breadcrumbs, Box } from '@mui/material';
import { ContentHeader } from '../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../common/helpers/HeadMenu/HeadMenu';
import { Button } from '../../common/helpers/buttons/Button';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './promotions.scss';
import { useDispatch } from 'react-redux';
import withServices from '../../hocs/withServices';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PromotionService from '../../services/promotionService';

function Promotions() {
	const [promotions, setPromotions] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		PromotionService.getActivePromotions()
			.then(response => {
				setPromotions(response.data.reverse());
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme>
				<h1>Акции поставщиков</h1>

				<Box>
					<Grid>
						{isLoading ? (
							<>
								<div className='loading-block'>
									<CircularProgress />
								</div>
							</>
						) : (
							<div className='promotion-blocks'>
								{promotions?.map((promotion, i) => (
									<div className='promotion' key={i}>
										<div className='details'>
											<p className='provider'>
												Поставщик: <span>{promotion?.provider?.name}</span>
											</p>
											<h4>{promotion?.title}</h4>

											<p className='subtitle'>{promotion?.subTitle}</p>
											<p className='description'>{promotion?.description}</p>
											<div className='dates'>
												<div>
													<p>Дата начало</p>
													<Chip
														label={promotion?.startDate.substring(0, 10)}
														icon={<AccessTimeIcon />}
													/>
												</div>
												<div>
													<p>Дата окончания</p>
													<Chip
														label={promotion?.finishDate.substring(0, 10)}
														color='warning'
														icon={<AccessTimeIcon />}
													/>
												</div>
											</div>
											<Button
												label='Перейти'
												width='250px'
												fontSize='16px'
												height='30px'
												hover
											/>
										</div>
										<div className='photo'>
											<img src={promotion?.photo[0]?.url} alt='' />
										</div>
									</div>
								))}
							</div>
						)}
					</Grid>
				</Box>
			</ContentScheme>
		</>
	);
}

export default Promotions;
