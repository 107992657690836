import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './cardButton.scss';
import { buttonStyles } from './ButtonStyles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTotals } from '../../../store/slices/Cart/cartSlice';

export const CardButton = () => {
	const cart = useSelector(state => state.cart);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getTotals());
	}, [cart]);

	return (
		<>
			<Link to='/basket'>
				<Button
					sx={buttonStyles}
					onClick={handleOpen}
					variant='outlined'
					className='cartButton'
					startIcon={<ShoppingCartIcon className='shop-icon' />}
				>
					{`${cart.cartTotalQuantity}`} товаров -{' '}
					{`${Number(cart.cartTotalAmount).toLocaleString()}`} тг.
				</Button>
			</Link>
		</>
	);
};
