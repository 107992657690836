import React from 'react';
import StocksSlider from '../../common/helpers/main/Stocks/StocksSlider/StocksSlider';
import { Head } from '../head/Head';
import { PartnerSlider } from '../../common/helpers/main/PartnersSlider/PartnerSlider';

export const Main = () => {
	return (
		<>
			<Head />
			{/* <About /> */}
			<StocksSlider />
			<PartnerSlider />
			{/* <ClientsBlock /> */}
		</>
	);
};
