import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './common/assets/styles/main.scss';
import reportWebVitals from './reportWebVitals';
import CircularProgress from '@mui/material/CircularProgress';
import { BrowserRouter as Router } from 'react-router-dom';
// import './i18n';
import { Provider } from 'react-redux';
import store from './store';
import { getTotals } from './store/slices/Cart/cartSlice';
import ServicesContext from './contexts/servicesContext';
import AuthService from './services/authService';
import OrderService from './services/orderService';
import PromotionService from './services/promotionService';
import FileUploadService from './services/fileUploadService';
import RegistrationService from './services/registrationService';
store.dispatch(getTotals());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<Suspense fallback={<CircularProgress />}>
			<App />
		</Suspense>
	</Provider>
	// </React.StrictMode>
);
reportWebVitals();
