import { sidebarAdvs }  from '../common/assets/data';
import { Link as DomLink } from 'react-router-dom';

const Adverts = () => {
    return (
      sidebarAdvs.map((adv, i)=>(
        <div key={i}
        style={{
          textAlign:'center'
        }}
        width='100%'
        >
          <DomLink to={adv.link} >
            <img src={adv.img} alt="" width='60%'/>
          </DomLink>
        </div>
      ))
    )
  }

export default Adverts;