const Constants = Object.freeze({
	BASE_URL: process.env.REACT_APP_BASE_URL,
	API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
	RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
	YANDEX_GEOCODE_API: process.env.REACT_APP_YANDEX_GEOCODE_API,
	YANDEX_GEOCODE_LINK: process.env.REACT_APP_YANDEX_GEOCODE_LINK,
	FILE_TYPES: {
		logotype: 'logotype',
		businessLicense: 'business_license',
		alcoholLicense: 'alcohol_license'
	}
});

export default Constants;

// export default class Constants {
//   BASE_URL = process.env.REACT_APP_BASE_URL;
//   API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// }
