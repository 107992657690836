import axios from 'axios';
import Constants from '../common/constants';
import { AESEncrypt } from '../global/Crypto-Helper';
import authAxios from '../common/authAxios';

class OrderService {
	baseURL = Constants.API_BASE_URL;

	static async makeOrder(data) {
		return await authAxios.post('/orders/make-order', data);
	}

	static getAllByPrincipal() {
		return authAxios.get(
			`${Constants.API_BASE_URL}/orders/get-all-by-principal`
		);
	}

	static getById(orderId) {
		return authAxios.get(
			`${Constants.API_BASE_URL}/orders/get-by-id?orderId=${orderId}`
		);
	}
}

export default OrderService;
