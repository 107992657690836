import React, { useState, useEffect } from 'react';
import { ContentHeader } from '../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../common/helpers/HeadMenu/HeadMenu';
import {
	Breadcrumbs,
	Typography,
	Grid,
	Select,
	FormControl,
	MenuItem,
	InputLabel,
	ButtonGroup,
	Button,
	Pagination
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AppsIcon from '@mui/icons-material/Apps';
import Constants from '../../common/constants';
import { useParams } from 'react-router-dom';
import withServices from '../../hocs/withServices';
import { category as categories } from '../../common/assets/data';
import goodsService from '../../services/goodsService';
import './goods.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../store/slices/Cart/cartSlice';
import { StocksSign } from '../../common/helpers/main/Stocks/StocksSign/StocksSign';

function Goods({ services }) {
	const pageSize = 4;
	const dispatch = useDispatch();

	const [sort, setSort] = useState('');
	const [pagination, setPagination] = useState({
		count: 0,
		from: 0,
		to: pageSize
	});

	const handleChange = event => {
		setSort(event.target.value);
	};

	const handlePageChange = (event, page) => {
		const from = (page - 1) * pageSize;
		const to = (page - 1) * pageSize + pageSize;

		setPagination({ ...pagination, from: from, to: to });
	};

	const [category, setCategory] = useState({});
	const [goods, setGoods] = useState([]);
	let { catId } = useParams();

	useEffect(() => {
		goodsService
			.getGoods({ from: pagination.from, to: pagination.to, catId: catId })
			.then(response => {
				setPagination({ ...pagination, count: response.count });
				setGoods(response.data);
			});

		let findedCaregory = categories.find(cat => cat.id === catId);

		if (findedCaregory) {
			setCategory(findedCaregory);
		}
	}, [catId, pagination.from, pagination.to]);

	const sidebarContent = () => {
		return <h3>Категории</h3>;
	};

	const handleAddToCart = (e, good) => {
		e.preventDefault();
		dispatch(addToCart(good));
	};

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme sidebar={sidebarContent()}>
				<h1>{category.title}</h1>
				<Breadcrumbs
					className='breadcrumbs'
					aria-label='breadcrumb'
					sx={{ color: 'rgba(0,0,0,.20)' }}
				>
					<Link underline='hover' color='inherit' to='/'>
						Главная
					</Link>
					<Link underline='hover' color='inherit' to='/catalog'>
						Каталог
					</Link>
					<Typography color='text.primary'>{category.title}</Typography>
				</Breadcrumbs>
				<Grid
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						m: '20px 0 10px 0'
					}}
				>
					<Grid
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							padding: '8px'
						}}
					>
						<FormControl
							sx={{ minWidth: 200, borderRadius: '50px' }}
							size='small'
						>
							<InputLabel id='demo-select-small'>Сортировка</InputLabel>
							<Select
								labelId='demo-select-small'
								id='demo-select-small'
								value={sort}
								label='Сортировка'
								onChange={handleChange}
								sx={{ borderRadius: '50px', backgroundColor: '#D9D9D978' }}
							>
								<MenuItem value=''>
									<em>Нету</em>
								</MenuItem>
								<MenuItem value='popularity'>По популярности</MenuItem>
								<MenuItem value='price'>По цене</MenuItem>
							</Select>
						</FormControl>
						<ButtonGroup
							disableElevation
							variant='contained'
							aria-label='Disabled elevation buttons'
							sx={{ ml: 2, height: '40px' }}
						>
							<Button sx={{ borderRadius: '50px' }}>
								<AppsIcon />
							</Button>
							<Button sx={{ borderRadius: '50px' }}>
								<MenuIcon />
							</Button>
						</ButtonGroup>
					</Grid>
					<Grid
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center'
						}}
					>
						<Typography>
							Товары {goods.length} из {pagination.count}
						</Typography>
					</Grid>
				</Grid>
				<Grid className='goods-block'>
					{goods.map(good => (
						<Link to={'/category/' + catId + '/good/' + good.id} key={good.id}>
							<div className='good'>
								<img src={Constants.BASE_URL + good.img} alt='' />
								<div className='stock-sign-con'>
									{good?.promotion ? (
										<div className='stock-sign'>
											<StocksSign>{good?.promotion?.discount}</StocksSign>
										</div>
									) : null}
								</div>
								<p>
									<span>{good.price} тг.</span>
								</p>
								<h4>{good.title}</h4>
								<button onClick={e => handleAddToCart(e, good)}>
									Добавить в корзину
								</button>
							</div>
						</Link>
					))}
				</Grid>
				<Grid
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '20px',
						marginTop: '20px'
					}}
				>
					<Pagination
						count={Math.ceil(pagination.count / pageSize)}
						onChange={handlePageChange}
					/>
				</Grid>
			</ContentScheme>
		</>
	);
}

export default withServices()(Goods);
