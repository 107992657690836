import React from "react";
import { menu, headBackground } from "../../common/assets/data";
import { Link } from "react-router-dom";
import logo from "../../common/assets/images/logo.svg";
import { Button } from "../../common/helpers/buttons/Button";
import { head } from "../../common/assets/data";
import { Grid } from "@mui/material";
import "./head.scss";

export const Head = () => {
  return (
    <>
      <section
        className="head"
        style={{
          backgroundImage:
            "linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(" +
            headBackground +
            ")",
          height: "700px",
        }}
      >
        <div className="container f_flex ">
          <div className="menu-logo d_flex">
            <div className="logo f_flex">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="menu">
              <ul className="f_flex">
                {menu.map((nav, i) => (
                  <li key={i}>
                    <Link to={nav.link}>{nav.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="about c_flex flexColumn">
            <h1 className="CText w100">{head.title}</h1>
            <p className="main-desc">{head.desc}</p>
            <div className="head-btns">
              <Link
                to={head.link1}
                style={{ marginRight: "20px", marginBottom: "50px" }}
              >
                <Button
                  label={head.btn1}
                  width="350px"
                  fontSize="18px"
                  height="50px"
                  borderRadius="200px"
                  textShadow="true"
                  btnLetterSpacing="true"
                />
              </Link>
              <Link to={head.link2}>
                <Button
                  label={head.btn2}
                  width="350px"
                  fontSize="18px"
                  height="50px"
                  borderRadius="200px"
                  textShadow="true"
                  btnLetterSpacing="true"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
