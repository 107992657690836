import React, { useEffect, useState } from 'react';
import { ContentHeader } from '../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../common/helpers/HeadMenu/HeadMenu';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Grid } from '@mui/material';
import goodsService from '../../services/goodsService';
import Constants from '../../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '../../common/helpers/buttons/Button';
import './aboutGood.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../store/slices/Cart/cartSlice';
import { StocksSign } from '../../common/helpers/main/Stocks/StocksSign/StocksSign';
import CatalogService from '../../services/CatalogService';

export const AboutGood = () => {
	const { catId, providerId, goodId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [category, setCategory] = useState();
	const [good, setGood] = useState();
	const [provider, setProvider] = useState();

	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		CatalogService.getProviderById({ providerId }).then(response => {
			setProvider(response.data);
		});

		// goodsService.getCategories({ catId }).then(response => {
		// 	setCategory(response[0]);
		// });

		goodsService
			.getGoodById({ goodId })
			.then(response => {
				setGood(response.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [catId, goodId, providerId]);

	const handleAddToCart = good => {
		dispatch(addToCart(good));
	};

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme>
				<Breadcrumbs
					className='breadcrumbs'
					aria-label='breadcrumb'
					sx={{ color: 'rgba(0,0,0,.20)', marginBottom: '20px' }}
				>
					<Link underline='hover' color='inherit' to='/'>
						Главная
					</Link>
					<Link underline='hover' color='inherit' to='/providers-categories'>
						Поставщики
					</Link>
					<Link
						underline='hover'
						color='inherit'
						to={'/provider/' + good?.provider?.id}
						state={{ category: good?.catgory }}
					>
						{good?.provider?.name}
					</Link>
					<Link underline='hover' to='/providers-categories'>
						{good?.category?.title}
					</Link>
				</Breadcrumbs>
				{isLoading ? (
					<>
						<div className='loading-block'>
							<CircularProgress />
						</div>
					</>
				) : (
					<>
						<Grid className='good-container'>
							<Grid className='good-head'>
								<Grid className='good-images'>
									<img
										src={
											good?.mainPhoto?.url
												? good?.mainPhoto?.url
												: `${Constants.BASE_URL}static/media/logo-black.2496a132b014dd3006c66cb13eebd443.svg`
										}
										alt=''
									/>
								</Grid>
								<Grid className='good-info'>
									<div>
										<h3>{good?.title}</h3>
									</div>
									{/* <p className='short-desc'>{good.short_desc}</p> */}
									<div className='priceAddCard'>
										<span>
											{good?.discount ? (
												<>
													<span className='stock-sign'>{good?.discount} %</span>
													<span className='old-price'>{good?.price} ₸</span>

													<span> {good?.discountprice} ₸</span>
												</>
											) : (
												<>
													<span>{good?.price} ₸</span>
												</>
											)}
										</span>

										<Button
											label='В корзину'
											width='40%'
											hover='true'
											btnFontWeight='true'
											onClick={() => handleAddToCart(good)}
										></Button>
									</div>
									{/* <h4>Характеристики</h4> */}
									<p className='short-desc'>
										Поставщик: <span>{good?.provider?.name}</span>
									</p>
									<p className='short-desc'>
										Торговая марка: <span>{good?.brand?.name}</span>
									</p>
									<h4>Описание</h4>
									<p>{good?.description}</p>
									{/* <ol>
								{(() => {
									const options = [];
									let i = 0;
									for (const goodSpec in good.specification) {
										options.push(
											<li key={i}>
												<div>{goodSpec}</div>
												<div></div>
												<div>{good.specification[goodSpec]}</div>
											</li>
										);
										i++;
									}
									return options;
								})()}
							</ol> */}
								</Grid>
							</Grid>
							<Grid className='good-body'></Grid>
						</Grid>
					</>
				)}
			</ContentScheme>
		</>
	);
};
