import React from "react";
import Slider from "react-slick";
import { partners, partnerBtn } from "../../../assets/data";
import { PartnerSlide } from "./PartnerSlide/PartnerSlide";
import { Button } from "../../buttons/Button";
import { Link } from "react-router-dom";
import { Heading } from "../heading/Heading";
export const PartnerSlider = () => {
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="slide-con" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="patner-slider" style={{ padding: "50px" }}>
            <Heading title={partnerBtn.title} fontSize={34} width={500} />
            <Slider {...settings}>
              {partners.map((partner, i) => (
                <PartnerSlide
                  key={i}
                  title={partner.title}
                  img={partner.logo}
                  link={partner.link}
                />
              ))}
            </Slider>
            <div className="partners-btn">
              <Link to={partnerBtn.link}>
                <Button
                  label={partnerBtn.label}
                  hover="true"
                  width={230}
                  height={30}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
