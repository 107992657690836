import React from "react";
import "./button.scss";

export const Button = ({
  label,
  btnType,
  typeBorder,
  width = 150,
  height = 40,
  borderRadius = 20,
  fontSize = 18,
  textShadow,
  btnLetterSpacing,
  btnFontWeight,
  hover,
  onClick,
}) => {
  return (
    <>
      <button
        type={btnType}
        className={
          "btn " +
          (typeBorder ? " border_button " : " default_button ") +
          (textShadow ? " btnTextShadow " : "") +
          (btnLetterSpacing ? " btnLetterSpacing " : "") +
          (textShadow ? " btnTextShadow " : "") +
          (btnFontWeight ? " btnFontWeight " : "") +
          (hover ? " hoverBlack " : "")
        }
        style={{
          width: width,
          height: height,
          fontSize: fontSize,
          borderRadius: borderRadius,
        }}
        onClick={onClick}
      >
        {label}
      </button>
    </>
  );
};
