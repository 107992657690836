import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Grid,
	Modal,
	Typography,
	Breadcrumbs,
	Button,
	Box
} from '@mui/material';
import { YMaps, Map, ZoomControl, Placemark } from '@pbe/react-yandex-maps';
import Constants from '../../common/constants';
import { ContentHeader } from '../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../common/helpers/HeadMenu/HeadMenu';
import Adverts from '../Adverts';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoomIcon from '@mui/icons-material/Room';
import './orderRegistration.scss';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from 'react-redux';
import { StocksSign } from '../../common/helpers/main/Stocks/StocksSign/StocksSign';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withServices from '../../hocs/withServices';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CatalogService from '../../services/CatalogService';
import OrderService from '../../services/orderService';
import { Button as CustomBtn } from '../../common/helpers/buttons/Button';
import {
	addToCart,
	removeFromCart,
	minusQauntity,
	getTotals
} from '../../store/slices/Cart/cartSlice';
import AuthService from '../../services/authService';

function OrderRegistration({ services }) {
	const [openSuccess, setOpenSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [center, setCenter] = useState([51.143974, 71.435806]);
	const [findedAddress, setFindedAddress] = useState();
	const [zoom, setZoom] = useState(5);
	const [address, setAddress] = useState();
	const mapState = React.useMemo(
		() => ({ center, zoom, controls: [] }),
		[center, zoom]
	);
	const [city, setCity] = useState();
	const [region, setRegion] = useState();
	const handleCloseSuccess = () => {
		setOpenSuccess(false);
		window.location = '/my-cabinet';
	};

	const handleGetGeoData = address => {
		console.log(address);
		if (address) {
			let url = `${Constants.YANDEX_GEOCODE_LINK}?apikey=${Constants.YANDEX_GEOCODE_API}&geocode=${address}&format=json`;
			fetch(url)
				.then(response => response.json())
				.then(response => {
					const responsePos =
						response.response.GeoObjectCollection.featureMember[0].GeoObject
							.Point.pos;
					const [longitude, latitude] = responsePos.split(' ');
					const newCenter = [parseFloat(latitude), parseFloat(longitude)];
					setZoom(17);
					setCenter(newCenter);
				});
		}
	};

	const handleChangeCity = event => {
		const regionId = event.target.value;
		CatalogService.getCitiesByRegion({ regionId })
			.then(response => {
				setCity(response.data);
			})
			.catch(error => {
				setCity('');
				toast.error('По этому региону города не найдены', {
					position: 'top-right'
				});
			});
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { register, handleSubmit } = useForm();
	const cart = useSelector(state => state.cart);

	useEffect(() => {
		dispatch(getTotals());
	}, [cart]);

	useEffect(() => {
		AuthService.checkToken();
		CatalogService.getAllRegion().then(response => {
			setRegion(response.data);
		});

		const regionId = 1;

		CatalogService.getCitiesByRegion({ regionId })
			.then(response => {
				setCity(response.data);
			})
			.catch(error => {
				setCity('');
				toast.error('По этому региону города не найдены', {
					position: 'top-right'
				});
			});
	}, []);

	const style = {
		textAlign: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4
	};

	const onSubmitOrder = async data => {
		if (center) {
			data.latitude = center[0];
			data.longitude = center[1];
		}
		const requestData = {
			ordersDtoRequestList: cart.cartItems,
			orderDetails: data
		};
		setIsLoading(true);

		OrderService.makeOrder(requestData)
			.then(response => {
				if (response.data !== null) {
					setOpenSuccess(true);
					localStorage.removeItem('Cart Items');
					localStorage.removeItem('cartTotalQuantity');
					localStorage.removeItem('cartTotalAmount');
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const Basket = () => {
		return (
			<>
				<div className='basket-sidebar'>
					<h4>Ваш заказ</h4>
					<p>
						Товаров: <span> {cart.cartTotalQuantity}</span>
					</p>
					<p>
						Сумма:{' '}
						<span> {Number(cart.cartTotalAmount).toLocaleString()} ₸</span>
					</p>
					{cart.priceWithoutDiscount - cart.cartTotalAmount !== 0 ? (
						<p>
							Cкидка от поставщиков:{' '}
							<span>
								-
								{Number(
									cart.priceWithoutDiscount - cart.cartTotalAmount
								).toLocaleString()}{' '}
								₸
							</span>
						</p>
					) : null}
					<p>
						Cкидка от SaudaLine(1%):{' '}
						<span>
							-
							{Number(
								Math.round((cart.cartTotalAmount / 100) * 1)
							).toLocaleString()}{' '}
							₸
						</span>
					</p>
					<div className='hr-divider'></div>
					<h5>
						Итоговая сумма:{' '}
						<span> {Number(cart.cartTotalAmount).toLocaleString()} ₸</span>
					</h5>
				</div>
			</>
		);
	};

	const OrderModal = () => {
		return (
			<Modal
				open={openSuccess}
				onClose={handleCloseSuccess}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						Заказ успешно оформлен
					</Typography>
					<Typography id='modal-modal-title-2' variant='h6' component='h2'>
						Спасибо за заказ!
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						{/* <span>Ваши номера заказов: </span> */}
						<br />
						<strong>Вы можете отслеживать свой заказ в личном кабинете</strong>
						<br />
						<button onClick={handleCloseSuccess}>
							Перейти в личный кабинет
						</button>
						<br />
						Внимание! Поставщики свяжутся с вами как можно скорее и помогут
						выбрать наиболее оптимальный способ оплаты и доставки.
					</Typography>
				</Box>
			</Modal>
		);
	};

	const LoaderModal = () => {
		return (
			<Modal
				open={isLoading}
				onClose={handleCloseSuccess}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<CircularProgress />
				</Box>
			</Modal>
		);
	};

	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme sidebar={Basket()}>
				<h1>Оформление заказа</h1>
				<Breadcrumbs
					className='breadcrumbs'
					aria-label='breadcrumb'
					sx={{ color: 'rgba(0,0,0,.20)' }}
				>
					<Link underline='hover' color='inherit' to='/'>
						Главная
					</Link>
					<Link underline='hover' color='inherit' to='/providers-categories'>
						Каталог
					</Link>
					<Link underline='hover' color='inherit' to='/basket'>
						Корзина
					</Link>
					<Typography color='text.primary'>Оформление заказа</Typography>
				</Breadcrumbs>
				<Box>
					<Grid>
						<div className='cart-items'>
							{cart.cartItems.length === 0 ? (
								<div className='noBasket'>
									<Typography
										sx={{
											textAlign: 'center',
											marginTop: '20px',
											fontSize: '20px'
										}}
									>
										Ваша корзина пусто
									</Typography>
									<Button
										sx={{ margin: '20' }}
										onClick={() => navigate(-1)}
										startIcon={<ArrowBackIcon />}
									>
										Вернуться назад
									</Button>
								</div>
							) : (
								<div className='basket'>
									<div className='for'>
										<form onSubmit={handleSubmit(onSubmitOrder)}>
											{/* <div className='contacts-deatils'>
												<h4>Контактные данные</h4>
												<div className='inputs'>
													<div className='one-input'>
														<label htmlFor='fio'>
															ФИО<span>*</span>
														</label>
														<input {...register('fio')} type='text' id='fio' />
													</div>
													<div className='two-input'>
														<div className='first-input'>
															<label htmlFor='email'>
																Эл.почта<span>*</span>
															</label>
															<input
																{...register('email')}
																type='email'
																id='email'
															/>
														</div>
														<div className='second-input'>
															<label htmlFor='tel'>
																Телефон<span>*</span>
															</label>
															<input {...register('tel')} type='tel' id='tel' />
														</div>
													</div>
												</div>
											</div> */}
											<div className='getting-way'>
												<h4>Адрес доставки</h4>
												<div className='inputs'>
													{/* <div className='two-input'>
														<div className='first-input'>
															<label htmlFor='region'>
																Регион<span>*</span>
															</label>
															<select
																{...register('region')}
																id='region'
																onChange={handleChangeCity}
																selected='selected'
															>
																{Array.isArray(region) &&
																	region.map(regions => (
																		<option value={regions.id} key={regions.id}>
																			{regions.nameRu}
																		</option>
																	))}
															</select>
														</div>
														<div className='second-input'>
															<label htmlFor='city'>
																Тел.<span>*</span>
															</label>
															<select
																{...register('city')}
																id='city'
																selected='selected'
															>
																{Array.isArray(city) &&
																	city.map(city => (
																		<option value={city.id} key={city.id}>
																			{city.name}
																		</option>
																	))}
															</select>
														</div>
													</div> */}
													<div className='two-input'>
														<div className='one-input'>
															<label htmlFor='street'>
																Город, улица и номер дома и квартиры
																<span>*</span>
															</label>
															<input
																{...register('address')}
																type='text'
																id='street'
																onChange={e => {
																	setAddress(e.target.value);
																}}
															/>
														</div>
													</div>
													<div className='two-input'>
														<div className='btn-map'>
															<button
																type='button'
																onClick={() => handleGetGeoData(address)}
															>
																<RoomIcon />
																Найти адрес на карте
															</button>
														</div>
													</div>
													<div className='two-input'>
														<div className='yandex-map'>
															<YMaps>
																<div>
																	<Map
																		state={mapState}
																		height={400}
																		width={850}
																	>
																		<ZoomControl options={{ float: 'right' }} />
																		{address && <Placemark geometry={center} />}
																	</Map>
																</div>
															</YMaps>
														</div>
													</div>
												</div>
											</div>
											<div className='comments'>
												<label htmlFor='street'>Комментарий</label>
												<textarea
													{...register('comment')}
													id='comment'
													cols='30'
													rows='7'
												></textarea>
											</div>
											<div className='btn-order'>
												<button
													style={{ margin: '20' }}
													onClick={() => navigate(-1)}
													type='button'
												>
													<ArrowBackIcon />
													Вернуться назад
												</button>
												<button type='submit'>Оформить заказ</button>
											</div>
										</form>
									</div>
								</div>
							)}
						</div>
					</Grid>
				</Box>
			</ContentScheme>
			<OrderModal />
			{isLoading && <LoaderModal />}
		</>
	);
}

export default withServices()(OrderRegistration);
