import React from 'react';
import { Alert } from '@mui/material';

const OrderStatus = ({ status }) => {
	switch (status) {
		case 'NEW':
			return <Alert severity='info'>Ожидается подтверждение</Alert>;
		case 'APPROVED':
			return <Alert severity='success'>Поставщик принял заказ</Alert>;
		case 'CANCELED':
			return <Alert severity='warning'>Поставщик отменил заказ</Alert>;
		case 'CLOSED':
			return <Alert severity='error'>Вы отменили заказ</Alert>;
		default:
			return 'Ошибка';
	}
};

export default OrderStatus;
