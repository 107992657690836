import axios from 'axios';
import Constants from '../common/constants';

const PromotionService = {
	getActivePromotions: cityId => {
		return axios.get(
			`${Constants.API_BASE_URL}/promotion/get-active-promotions?cityId=${cityId}`
		);
	}
};

export default PromotionService;
