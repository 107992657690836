import React from 'react';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MenuIcon from '@mui/icons-material/Menu';

export const HeadMenu = () => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const ModalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4
	};
	const CatalogMenuStyle = {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '10px'
	};
	return (
		<>
			<Container>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{
						border: '1px solid #ccc',
						marginBottom: '20px',
						padding: '10px 0',
						fontSize: '18px'
					}}
				>
					<Grid item xs={6} md={6} align='left'>
						<Link onClick={handleOpen} style={CatalogMenuStyle}>
							<MenuIcon sx={{ marginRight: '5px', marginTop: '-2px' }} />
							Каталог товаров
						</Link>
					</Grid>
					<Grid
						item
						xs={6}
						md={6}
						align='right'
						sx={{
							'& ul': {
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',

								'& li': {
									marginRight: '50px',

									'& :hover': {
										color: '#FCA522'
									}
								}
							}
						}}
					>
						<ul>
							<li>
								<Link to='/promotions'>Акции</Link>
							</li>
							<li>
								<Link to='/providers-categories'>Фирмы</Link>
							</li>
							<li>
								<Link>Контакты</Link>
							</li>
						</ul>
					</Grid>
				</Grid>
			</Container>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={ModalStyle}>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						Каталог товаров
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						товары
					</Typography>
				</Box>
			</Modal>
		</>
	);
};
