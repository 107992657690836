import React, { useState } from 'react';
import { Grid, Typography, TextField, CircularProgress } from '@mui/material';
import logo from '../../common/assets/images/logo-black.svg';
import { Link, useNavigate } from 'react-router-dom';
import './login.scss';
import AuthService from '../../services/authService';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';

function LoginPage() {
	const CustomDiv = styled('div')({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh'
	});
	const CustomTextField = styled(TextField)({
		marginBottom: '20px',
		'& label.Mui-focused': {
			color: '#FCA522'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#FCA522'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#FCA522'
			},
			'&:hover fieldset': {
				borderColor: '#FCA522'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#FCA522'
			}
		}
	});
	let navigate = useNavigate();
	const { register, handleSubmit } = useForm();
	const [activate, setActivate] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const onSubmit = data => {
		setLoading(true);
		AuthService.login(data)
			.then(response => {
				if (!response) {
					setActivate(true);
					return;
				}
				navigate(-1);
			})
			.catch(error => setErrorMessage(error))
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			{loading ? (
				<CustomDiv>
					<CircularProgress />
				</CustomDiv>
			) : (
				<div className='page-container'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Link to='/' className='logo-login'>
							<img className='logo' src={logo} alt='' />
						</Link>
						<Typography>Вход в личный кабинет</Typography>
						{errorMessage && (
							<Typography variant='body1' color='error'>
								{errorMessage}
							</Typography>
						)}
						<CustomTextField
							required
							name='email'
							type='text'
							placeholder=''
							{...register('login')}
							label='Логин или эл. почта'
							variant='outlined'
						/>
						<br />
						<CustomTextField
							required
							{...register('password')}
							name='password'
							type='password'
							label='Пароль'
							variant='outlined'
						/>
						<div className='buttons'>
							<button type='submit'>Войти</button>
							<Link to='/registration' className='regBtn'>
								<button>Регистрация</button>
							</Link>
						</div>
						<br />
						<Link to='/reset-password'>Забыли пароль?</Link>
					</form>
				</div>
			)}
		</>
	);
}

export default LoginPage;
