import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ContentHeader } from '../../common/Content/ContentHeader/ContentHeader';
import { ContentScheme } from '../../common/helpers/contentScheme/ContentScheme';
import { HeadMenu } from '../../common/helpers/HeadMenu/HeadMenu';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import OrderModal from '../../components/OrderModal/OrderModal';
import {
	Paper,
	MenuList,
	MenuItem,
	Box,
	Grid,
	Typography,
	Button,
	Accordion,
	AccordionDetails,
	Alert,
	Modal
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import grantPermission from '../../common/helpers/grantPermission';
import './my-cabinet.scss';
import OrderStatus from '../../components/OrderStatus/OrderStatus';
import AuthService from '../../services/authService';
import OrderService from '../../services/orderService';

const SidebarMenu = () => {
	return (
		<>
			<Paper sx={{ marginBottom: '20px' }}>
				<MenuList>
					<MenuItem
						disabled
						color='primary'
						sx={{ color: 'black', fontWeight: 'Bold' }}
					>
						Мой кабинет
					</MenuItem>
					<MenuItem> Профиль</MenuItem>
					<MenuItem>Мои заказы</MenuItem>
					<MenuItem>Выйти</MenuItem>
				</MenuList>
			</Paper>
		</>
	);
};

function MyCabinet() {
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const [orders, setOrders] = useState();

	const [openOrderModal, setOpenOrderModal] = useState(false);
	const [selectedOrderId, setSelectedOrderId] = useState(null);
	const handleOpen = orderId => {
		setOpenOrderModal(true);
		setSelectedOrderId(orderId);
	};
	const handleClose = () => {
		setOpenOrderModal(false);
		setSelectedOrderId(null);
	};

	if (!AuthService.isLoggedIn() || !grantPermission('ROLE_CUSTOMER')) {
		window.location = '/login';
	}

	useEffect(() => {
		setIsLoading(true);
		OrderService.getAllByPrincipal()
			.then(response => {
				setOrders(response.data.reverse());
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);
	return (
		<>
			<ContentHeader />
			<HeadMenu />
			<ContentScheme sidebar={SidebarMenu()}>
				<h2>Мои заказы</h2>
				<Box>
					{isLoading ? (
						<>
							<div className='loading-block'>
								<CircularProgress />
							</div>
						</>
					) : (
						<>
							<Grid>
								<div className='cart-items'>
									{orders?.length === 0 ? (
										<div className='noBasket'>
											<Typography
												sx={{
													textAlign: 'center',
													marginTop: '20px',
													fontSize: '20px'
												}}
											>
												Вы еще не сделали заказ
											</Typography>
											<Button
												sx={{ margin: '20' }}
												onClick={() => navigate(-1)}
												startIcon={<ArrowBackIcon />}
											>
												Вернуться назад
											</Button>
										</div>
									) : (
										<div className='basket'>
											{orders?.map((order, j) => {
												return (
													<>
														<div
															className='order-btn'
															onClick={() => handleOpen(order.id)}
														>
															<div className='cabinet-order-details'>
																<div className='details'>
																	<p> Номер заказа: {order.id}</p>
																	<p>
																		Поставщик:
																		{order.provider.name}
																	</p>
																	<p>
																		<span>
																			Сумма заказа:{' '}
																			{order.totalAmountWithDiscount} ₸
																		</span>
																		{'     '}
																		<span>Товаров: {order.totalQuantity}</span>
																	</p>
																	<p>
																		Дата заказа: {formatDate(order.created)}
																	</p>
																</div>
																<div className='details'>
																	<OrderStatus status={order.status} />
																</div>
															</div>
														</div>
														{selectedOrderId === order.id && (
															<OrderModal
																orderId={order.id}
																open={openOrderModal}
																handleClose={handleClose}
															/>
														)}
													</>
												);
											})}
										</div>
									)}
								</div>
							</Grid>
						</>
					)}
				</Box>
			</ContentScheme>
		</>
	);
}

function formatDate(dateString) {
	const date = new Date(dateString);
	const options = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric'
	};
	return date.toLocaleString('en-GB', options);
}

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .00)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(0.5)
	}
}));

export default MyCabinet;
