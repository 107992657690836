import React from 'react';
import { Link } from 'react-router-dom';
import './partner-slide.scss';

export const PartnerSlide = ({title, img, link}) => {  
  return (
    <>
        <div className="partners-slider">
            <Link to={link}>
                <img src={img} alt={title} />
            </Link>
        </div>
    </>
  )
}
