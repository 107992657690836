import React, { useState, useEffect } from 'react';
import { Box, Typography, Alert, Modal } from '@mui/material';
import withServices from '../../hocs/withServices';
import OrderStatus from '../OrderStatus/OrderStatus';
import CircularProgress from '@mui/material/CircularProgress';
import { StocksSign } from '../../common/helpers/main/Stocks/StocksSign/StocksSign';
import OrderService from '../../services/orderService';
import './order-modal.scss';
import Constants from '../../common/constants';

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 850,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	overflowY: 'auto',
	maxHeight: '80vh'
};

function formatDate(dateString) {
	const date = new Date(dateString);
	const options = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric'
	};
	return date.toLocaleString('en-GB', options);
}
function OrderModal({ services, orderId, open, handleClose }) {
	const [isLoading, setIsLoading] = useState(false);
	const [order, setOrder] = useState();
	useEffect(() => {
		setIsLoading(true);
		OrderService.getById(orderId)
			.then(response => {
				setOrder(response.data);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [orderId]);
	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={modalStyle} className='modal-order'>
					{isLoading ? (
						<>
							<div className='loading-block'>
								<CircularProgress />
							</div>
						</>
					) : (
						<>
							<div className='modal-order-details'>
								<div className='details'>
									<p> Номер заказа: {order?.id}</p>
									<h5>Поставщик: {order?.provider?.name}</h5>
									<p>Дата заказа: {formatDate(order?.created)}</p>
								</div>
								<div className='details'>
									<OrderStatus status={order?.status} />
									<p>Дата поставки: {formatDate(order?.created)}</p>
								</div>
							</div>
							<hr />
							{order?.details?.map((product, i) => (
								<div className='cart-item' key={i}>
									<div className='cart-num'>{++i}</div>
									<div className='cart-info'>
										<div className='img'>
											<img
												src={
													product?.product?.mainPhoto?.url
														? product?.product?.mainPhoto?.url
														: `${Constants.BASE_URL}static/media/logo-black.2496a132b014dd3006c66cb13eebd443.svg`
												}
												alt=''
											/>
											<div className='stock-sign-con'>
												{product?.discount != 0 ? (
													<div className='stock-sign'>
														<StocksSign>{product?.discount}</StocksSign>
													</div>
												) : null}
											</div>
										</div>
										<div className='desc'>
											<h4>{product?.product?.title}</h4>
											<p>{product?.product?.short_desc}</p>
											<h5>Фирма: {order?.provider?.name}</h5>
										</div>
										<div className='actions'>
											<p className='price'>
												{/* {cartItem.discount ? (
																		<p className='stock-title'>
																			Цена со скидкой {cartItem.discount}%
																		</p>
																	) : null} */}
												{product?.discount != 0 ? (
													<>{product?.sumWithDiscount} ₸</>
												) : (
													<>{product?.sum} ₸</>
												)}
												<br />
												<span>
													{product?.discount != 0 ? (
														<>
															<span className='old-price'>
																{product?.price} ₸
															</span>
															{` `}
															<span className='new-price'>
																{product?.priceWithDiscount} ₸/шт
															</span>
														</>
													) : (
														<>
															<span>{product?.price} ₸/шт</span>
														</>
													)}
												</span>
												{product?.discount != 0 ? (
													<p className='stock-title'>
														Цена со скидкой {product?.discount}%
													</p>
												) : null}
												{/* <span>
													{product?.discount
														? product?.priceWithoutStock
														: product?.product?.price}{' '}
													₸/шт
												</span> */}
											</p>
											<div className='button-group'>
												<div className='btn-gr'>
													{/* <button
																		// onClick={() =>
																		// 	handleMinusQuantity(cartItem)
																		// }
																		>
																			<RemoveIcon />
																		</button>
																		<input
																			type='number'
																			value={cartItem.cartQuantity}
																		/> */}
													<span> {product?.quantity} шт</span>
													{/* <button
																		// onClick={() => handleAddToCart(cartItem)}
																		>
																			<AddIcon />
																		</button> */}
												</div>
												{/* <button
																		className='delete-btn'
																		// onClick={() =>
																		// 	handleRemoveFromCart(cartItem)
																		// }
																	>
																		<DeleteForeverIcon />
																	</button> */}
											</div>
										</div>
									</div>
								</div>
							))}
							<div className='footer'>
								<div className='details'>
									<span> Товаров: {order?.details?.length}</span>
									<span> Кол.: {order?.totalQuantity} шт.</span>
								</div>
								<div className='details'>
									<span> Сумма: {order?.totalAmount}₸</span>
									{order?.totalDiscount != 0 ? (
										<span>
											{' '}
											Скидка: -{' '}
											{order?.totalAmount - order?.totalAmountWithDiscount}
										</span>
									) : null}
									<span>
										{' '}
										Cкидка от SaudaLine(1%): -{' '}
										{Math.round((order?.totalAmountWithDiscount / 100) * 1)}₸
									</span>
									<span className='itog'>
										Итог:{' '}
										{order?.totalAmountWithDiscount -
											Math.round(
												(order?.totalAmountWithDiscount / 100) * 1
											)}{' '}
										₸
									</span>
								</div>
							</div>
						</>
					)}
				</Box>
			</Modal>
		</>
	);
}

export default withServices()(OrderModal);
