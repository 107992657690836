import React from "react";
import { ContentHeader } from "../../../common/Content/ContentHeader/ContentHeader";
import { ContentScheme } from "../../../common/helpers/contentScheme/ContentScheme";
import { HeadMenu } from "../../../common/helpers/HeadMenu/HeadMenu";
import "./style.scss";

export const AboutToCustomers = () => {
  return (
    <>
      <ContentHeader />
      <HeadMenu />
      <ContentScheme>
        <div className="AboutText">
          {/* <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "28px",
            }}
          >
            Компания Saudaline.kz занимается услугой продажи.
          </p> */}
          <p style={{ textAlign: "center", color: "#15c5e5" }}>
            Мы предоставляем бесплатный доступ к базе прямых поставщиков с
            ценами.
            <br />С Нами Вы сможете взять свой бизнес под контроль и сделать его
            успешным и прибыльным!
          </p>
          <p>
            Вы владелец магазина, торговой точки или сети магазинов? Если
            пользоваться сервисом «Saudaline.kz», покупать товары становится
            проще, быстрее, безопаснее. Предлагаем пройти простую и быструю
            регистрацию и получить доступ к базе фирм.
          </p>
          <p>
            Прежде, чем начать пользоваться доступным функционалом сервиса,
            требуется пройти несложную регистрацию. В первую очередь это нужно
            для подтверждения предоставляемых данных. База доступна только
            владельцам торговых точек (магазинов) и их сотрудникам, имеющим
            доступ от руководства. Это исключает случаи неправомерного
            использования сервиса. Пользоваться услугами сервиса - безопасно.
          </p>
          <h2>Какие возможности доступны?</h2>
          <p>«Saudaline.kz» предоставляет следующие возможности:</p>
          <ul>
            <li>
              Каталог поставщиков.&nbsp;Обширный список поставщиков с подробной
              информацией о каждой компании. В том числе по ассортименту, зонам
              доставки, точному расположению на карте города. Указаны прямые
              цены от поставщиков, что значительно упрощает подбор предложения.
            </li>
            <li>
              Список акций.&nbsp;Чтобы оптовая закупка продуктов проходила с
              минимальными затратами, для владельцев магазинов предлагается
              раздел с акциями. Здесь представлены специальные предложения
              поставщиков со скидками. Список акций регулярно обновляется и
              пополняется новыми, вы первыми узнаете о новых уникальных
              предложениях!
            </li>
          </ul>
          <p>
            Главный повод воспользоваться функционалом сервиса «Saudaline.kz»–
            это бесплатная возможность сделать бизнес более прибыльным и
            конкурентоспособным за счет организации стабильных и недорогих
            поставок. Дополнительные преимущества:
          </p>
          <ul>
            <li>
              Понятный интерфейс.&nbsp;Сервис предоставляет простой и удобный в
              работе интерфейс для экономии Вашего времени. Доступен с
              компьютеров, планшетов и смартфонов с выходом в Интернет.
            </li>
            <li>
              Богатый ассортимент товаров.&nbsp;С сервисом на постоянной основе
              сотрудничают свыше 500 проверенных и надежных поставщиков. Список
              товаров постоянно пополняется – закажите нужные товары и получите
              самые выгодные предложения.
            </li>
          </ul>
          <p>
            Заказывайте товары и продукты питания в Казахстане через сервис
            «Saudaline.kz» и сделайте процесс заказа товаров быстрым и выгодным!
            Пройдите простую регистрацию, станьте участником проекта и сделайте
            заказ уже сегодня!
          </p>
        </div>
      </ContentScheme>
    </>
  );
};
