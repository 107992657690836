import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/system';
import { Grid, Modal, Box, Typography, TextField } from '@mui/material';
import AuthenticatedContent from '../AuthenticatedContent';
import AuthService from '../../services/authService';
import CatalogService from '../../services/CatalogService';

function Header() {
	const { t, i18n } = useTranslation();
	const [openCityChoose, setOpenCityChoose] = useState(false);
	const [isCityBlockVisible, setIsCityBlockVisible] = useState(false);
	const [cityName, setCityName] = React.useState('');
	const [topCities, setTopCities] = useState([]);
	const [cities, setCities] = React.useState([]);
	const handleOpen = () => setOpenCityChoose(true);
	const handleClose = () => setOpenCityChoose(false);

	useEffect(() => {
		if (localStorage.getItem('cityId') === null) {
			localStorage.setItem('cityId', '1');
			localStorage.setItem('cityName', 'Алматы');
		}
		CatalogService.getCitiesByProductsCount().then(({ data }) => {
			setTopCities(data);
		});
	}, []);

	useEffect(() => {
		CatalogService.getCityByName(cityName).then(({ data }) => {
			setCities(data);
		});
	}, [cityName]);

	const changeLanguage = language => {
		i18n.changeLanguage(language);
	};

	const handleTextFiledChange = e => {
		setIsCityBlockVisible(true);
		setCityName(e.target.value);
	};

	const handleChooseCity = city => {
		setCityName(city.name);
		localStorage.setItem('cityId', city?.id);
		localStorage.setItem('cityName', city?.name);
		setIsCityBlockVisible(false);
		window.location.reload();
	};

	return (
		<>
			<section className='header'>
				<Container>
					<Grid container>
						<Grid item xs={6} md={6}>
							<ul>
								<li>
									<i className='fa fa-phone-alt'></i>
									<label>+7 707 695 5497</label>
								</li>
								<li onClick={handleOpen} style={{ cursor: 'pointer' }}>
									<i
										className='fa fa-map-marker'
										style={{ cursor: 'pointer' }}
									></i>
									<label style={{ cursor: 'pointer' }}>
										{localStorage.getItem('cityName')}
									</label>
								</li>
							</ul>
							{/* <div className='left'>
									
								</div>
								<div className='left'>
									
								</div> */}
						</Grid>
						<Grid item xs={6} md={6} align='right'>
							<div className='right row d_flex'>
								<div className='search-input'>
									<i className='fa fa-search'></i>
									<input type='text' />
								</div>
								{!AuthService.isLoggedIn() && (
									<>
										<div className='sign-in'>
											<i className='fas fa-sign-in-alt'></i>
											<Link to='/login'>Войти</Link>
										</div>
										<div className='sign-in'>
											<i className='fas fa-user-plus'></i>
											<Link to='/registration'>Регистрация</Link>
										</div>
									</>
								)}
								{AuthService.isLoggedIn() && (
									<>
										<AuthenticatedContent role={'ROLE_ADMIN'}>
											<div className='sign-in'>
												<i className='fas fa-sign-in-alt'></i>
												<a href='/admin'>Админ панель</a>
											</div>
										</AuthenticatedContent>
										<AuthenticatedContent role={'ROLE_PROVIDER'}>
											<div className='sign-in'>
												<i className='fas fa-sign-in-alt'></i>
												<a href='/admin'>Админ панель</a>
											</div>
										</AuthenticatedContent>
										<AuthenticatedContent role={'ROLE_CUSTOMER'}>
											<div className='sign-in'>
												<i className='fas fa-sign-in-alt'></i>
												<a href='/my-cabinet'>Мой кабинет</a>
											</div>
										</AuthenticatedContent>
										<AuthenticatedContent role={'ROLE_USER'}>
											<div className='sign-in'>
												<i className='fas fa-sign-in-alt'></i>
												<a href='/cabinet'>{t('my-cabinet')}</a>
											</div>
										</AuthenticatedContent>
										<div className='sign-in'>
											<i className='fas fa-sign-in-alt'></i>
											<Link onClick={AuthService.logout}>Выход</Link>
										</div>
									</>
								)}
								{/* <select
									name='lang'
									id='lang'
									className='lang'
									onChange={e => changeLanguage(e.target.value)}
								>
									<option value='ru'>RU</option>
									<option value='kz'>KZ</option>
								</select> */}
							</div>
						</Grid>
						<Modal
							open={openCityChoose}
							onClose={handleClose}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'
						>
							<Box className='cityChooseBox'>
								<Typography
									id='modal-modal-title'
									variant='h6'
									component='h2'
									className='cityBoxHeader'
								>
									Выберите город
									<i className='fa fa-times' onClick={handleClose}></i>
								</Typography>
								<Grid id='modal-modal-description' sx={{ mt: 2 }}>
									<div
										className='formField'
										onMouseEnter={() => setIsCityBlockVisible(true)}
										onMouseLeave={() => setIsCityBlockVisible(false)}
									>
										<TextField
											id='outlined-basic'
											label='Введите название города'
											variant='outlined'
											sx={{ width: '100%' }}
											value={cityName}
											onFocus={() => setIsCityBlockVisible(true)}
											onChange={handleTextFiledChange}
										></TextField>
										<div
											className={`cityChooseBlock ${
												isCityBlockVisible
													? 'cityChooseBlockActive'
													: 'cityChooseBlockNotActive'
											}`}
										>
											<div className='cityItems'>
												{cities.map(city => {
													return (
														<div
															className='cityItem'
															key={city.id}
															onClick={() => handleChooseCity(city)}
														>
															{city?.name} - {city?.region.nameRu}
														</div>
													);
												})}
											</div>
										</div>
									</div>

									<div className='cities'>
										<ul>
											{topCities.map(city => {
												return (
													<li
														key={city?.id}
														onClick={() => handleChooseCity(city)}
													>
														{city?.name}
													</li>
												);
											})}
										</ul>
									</div>
								</Grid>
							</Box>
						</Modal>
					</Grid>
				</Container>
			</section>
		</>
	);
}

export default Header;
