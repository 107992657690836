import React, { useState, useEffect, useRef } from 'react';
import withServices from '../../hocs/withServices';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import {
	Grid,
	Container,
	Paper,
	TextField,
	RadioGroup,
	FormLabel,
	FormControlLabel,
	Radio,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Input,
	Tabs,
	Tab,
	Checkbox,
	Button,
	Typography,
	CircularProgress
} from '@mui/material';
import './registration.scss';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import CatalogService from '../../services/CatalogService';
import AuthService from '../../services/authService';
import Constants from '../../common/constants';
import FileUploadService from '../../services/fileUploadService';
import ReCAPTCHA from 'react-google-recaptcha';

const CustomDiv = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh'
});

function Registration({ services }) {
	const [city, setCity] = useState('');
	const [region, setRegion] = useState('');
	const [regionId, setRegionId] = useState();
	const [providerCategories, setProviderCategories] = useState('');
	const [typeBusiness, setTypeBusiness] = useState(0);
	const [isCheckedTerms, setIsCheckedTerms] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const [passErrText, setPassErrText] = useState('');
	const [passwordError, setPasswordError] = useState(false);

	const [loginErrText, setLoginErrText] = useState('');
	const [loginError, setLoginError] = useState(false);
	const [selectImage, setSelectImage] = useState();
	const [businessLicense, setBusinessLicense] = useState();
	const [alcoholLicense, setAlcoholLicense] = useState();
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, reset } = useForm();
	const captchaRef = useRef(null);

	const handleChangeTypeBusiness = (event, newValue) => {
		setTypeBusiness(newValue);
	};

	const imageChange = e => {
		if (e.target.files && e.target.files.length > 0) {
			setSelectImage(e.target.files[0]);
		}
	};

	const businessLicenseChange = e => {
		if (e.target.files && e.target.files.length > 0) {
			setBusinessLicense(e.target.files[0]);
		}
	};

	const alcoholLicenseChange = e => {
		if (e.target.files && e.target.files.length > 0) {
			setAlcoholLicense(e.target.files[0]);
		}
	};

	const resetBusinessLicense = () => {
		setBusinessLicense();
	};

	const resetAlcoholLicense = () => {
		setAlcoholLicense();
	};

	const deleteSelectImage = () => {
		setSelectImage();
	};

	const handleChangeCity = event => {
		const regionId = event.target.value;
		CatalogService.getCitiesByRegion({ regionId })
			.then(response => {
				setCity(response.data);
			})
			.catch(error => {
				setCity('');
				toast.error('По этому региону города не найдены', {
					position: 'top-right'
				});
			});
	};

	const handleCheckLogin = event => {
		AuthService.checkLogin(event.target.value)
			.then(response => {
				if (response.data) {
					setLoginErrText('Логин уже занят');
					setLoginError(true);
				} else {
					setLoginErrText('');
					setLoginError(false);
				}
			})
			.catch(error => setErrorMessage(error));
	};
	const handleCheckPassword = event => {
		if (document.getElementById('pass').value !== event.target.value) {
			setPassErrText('Пароли не совпадают');
			setPasswordError(true);
		} else {
			setPassErrText('');
			setPasswordError(false);
		}
	};
	const handleCheckedChange = event => {
		setIsCheckedTerms(event.target.checked);
	};

	const onSubmitRegistration = async data => {
		data['typeBusiness'] = typeBusiness;
		if (businessLicense) {
			data['businessLisence'] = businessLicense;
			data['businessLisenceType'] = Constants.FILE_TYPES.businessLicense;
		}
		if (alcoholLicense) {
			data['alcoholLicense'] = alcoholLicense;
			data['alcoholLicenseType'] = Constants.FILE_TYPES.alcoholLicense;
		}

		const token = captchaRef.current.getValue();
		captchaRef.current.reset();
		if (token) {
			data['token'] = token;
			if (selectImage && selectImage.size !== null) {
				setLoading(true);
				await FileUploadService.uploadPhotos(selectImage)
					.then(response => {
						const logotype = {
							uid: uuidv4(),
							name: selectImage.name,
							response: response.data,
							type: selectImage.type,
							size: selectImage.size,
							percent: 100,
							status: 'done'
						};
						data['logoType'] = logotype;
					})
					.catch(error => {
						toast.error('Изображение не загрузилось', {
							position: 'top-right'
						});
					})
					.finally(() => {
						setLoading(false);
					});
			}
			setLoading(true);
			await AuthService.registration(data)
				.then(response => {
					toast.success('На вашу почту выслана ссылка для активации аккаунта', {
						position: 'bottom-left'
					});
				})
				.catch(error => setErrorMessage(error))
				.finally(() => {
					setLoading(false);
					reset();
				});
		}
	};

	useEffect(() => {
		CatalogService.getAllRegion().then(response => {
			setRegion(response.data);
		});

		CatalogService.getAllProviderCategories().then(response => {
			setProviderCategories(response.data);
		});
	}, []);

	return (
		<>
			{loading ? (
				<CustomDiv>
					<CircularProgress />
				</CustomDiv>
			) : (
				<Container
					className='reg-page'
					maxWidth='lg'
					sx={{ marginBottom: '50px' }}
				>
					<Grid
						sx={{
							width: '100%',
							textAlign: 'center',
							marginTop: '20px',
							marginBottom: '30px'
						}}
					>
						<h3 style={{ color: '#FCA522', fontSize: '25px' }}>Регистрация</h3>
						{errorMessage && (
							<Typography variant='body1' color='error'>
								{errorMessage}
							</Typography>
						)}
					</Grid>
					<form onSubmit={handleSubmit(onSubmitRegistration)}>
						<Grid className='reg-block' container spacing={4}>
							<Grid item xs={12} md={6}>
								<Paper className='reg-cards'>
									<h4>Данные организации</h4>
									<Grid sx={{ marginBottom: '25px' }}>
										<Tabs
											className='tabs'
											value={typeBusiness}
											onChange={handleChangeTypeBusiness}
										>
											<Tab className='customers-tab' label='Магазин' />
											<Tab className='providers-tab' label='Поставщик' />
										</Tabs>
									</Grid>
									{typeBusiness > 0 && (
										<Grid
											sx={{
												width: '100%',
												display: 'flex',
												marginBottom: '25px'
											}}
										>
											<Grid sx={{ width: '100%', marginRight: '15px' }}>
												<FormControl required fullWidth>
													<InputLabel id='region-list'>
														Категория поставщика
													</InputLabel>
													<Select
														labelId='region-list'
														label='Категория поставщика'
														{...register('providerCategory')}
													>
														{Array.isArray(providerCategories) &&
															providerCategories.map(providerCategory => (
																<MenuItem
																	value={providerCategory.id}
																	key={providerCategory.id}
																>
																	{providerCategory.nameRu}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									)}
									<TextField
										label='Название организации:'
										variant='outlined'
										{...register('businessName')}
										required
										fullWidth
										sx={{ marginBottom: '25px' }}
									/>
									<Grid
										sx={{
											width: '100%',
											display: 'flex',
											marginBottom: '25px'
										}}
									>
										<Grid item xs={12} md={6} sx={{ width: '50%' }}>
											<InputMask
												mask='999999999999'
												{...register('businessNumber')}
												name='businessNumber'
											>
												{inputProps => (
													<TextField
														required
														{...inputProps}
														label='ИИН/БИН:'
														variant='outlined'
													/>
												)}
											</InputMask>
										</Grid>
										<Grid
											item
											xs={12}
											md={6}
											sx={{ width: '50%', display: 'flex' }}
										>
											<FormLabel
												required
												id='demo-row-radio-buttons-group-label'
												sx={{
													width: '30%',
													display: 'flex',
													alignItems: 'center'
												}}
											>
												Форма:
											</FormLabel>
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												name='row-radio-buttons-group'
												sx={{ width: '70%' }}
											>
												<FormControlLabel
													{...register('formBusiness')}
													value='1'
													control={<Radio />}
													label='ИП'
												/>
												<FormControlLabel
													{...register('formBusiness')}
													value='2'
													control={<Radio />}
													label='ТОО'
												/>
											</RadioGroup>
										</Grid>
									</Grid>
									<Grid
										sx={{
											width: '100%',
											display: 'flex',
											marginBottom: '25px'
										}}
									>
										<Grid sx={{ width: '50%', marginRight: '15px' }}>
											<FormControl required fullWidth>
												<InputLabel id='region-list'>Область</InputLabel>
												<Select
													labelId='region-list'
													label='Область'
													{...register('region')}
													onChange={handleChangeCity}
												>
													{Array.isArray(region) &&
														region.map(region => (
															<MenuItem value={region.id} key={region.id}>
																{region.nameRu}
															</MenuItem>
														))}
												</Select>
											</FormControl>
										</Grid>
										<Grid sx={{ width: '50%' }}>
											<FormControl required fullWidth>
												<InputLabel id='city-list'>Город</InputLabel>
												<Select
													labelId='city-list'
													label='Город'
													{...register('city')}
												>
													{Array.isArray(city) &&
														city.map(city => (
															<MenuItem value={city.id} key={city.id}>
																{city.name}
															</MenuItem>
														))}
												</Select>
											</FormControl>
										</Grid>
									</Grid>
									<Grid sx={{ width: '100%', marginBottom: '25px' }}>
										<TextField
											required
											label='Адрес:'
											{...register('address')}
											variant='outlined'
											fullWidth
										/>
									</Grid>
									<Grid
										sx={{
											width: '100%',
											marginBottom: '28px',
											display: 'flex'
										}}
									>
										<Grid sx={{ width: '50%' }}>
											<p>Лицензия на ИП/ТОО (необязательно):</p>
										</Grid>
										{businessLicense ? (
											<Grid sx={{ width: '50%' }}>
												<Typography>
													Выбранный файл:{' '}
													<strong style={{ fontSize: '12px' }}>
														{businessLicense.name}
													</strong>
												</Typography>
												<Button
													variant='outlined'
													color='error'
													onClick={resetBusinessLicense}
												>
													Удалить
												</Button>
											</Grid>
										) : (
											<Grid sx={{ width: '50%' }}>
												<Button
													variant='outlined'
													component='label'
													sx={{ width: '100%' }}
												>
													Загрузить документ
													<input
														type='file'
														hidden
														onChange={businessLicenseChange}
													/>
												</Button>
											</Grid>
										)}
									</Grid>
									<Grid
										sx={{
											width: '100%',
											marginBottom: '25px',
											display: 'flex'
										}}
									>
										<Grid sx={{ width: '50%' }}>
											<p>Лицензия на алкоголь (необязательно):</p>
										</Grid>
										{alcoholLicense ? (
											<Grid sx={{ width: '50%' }}>
												<Typography>
													Выбранный файл:{' '}
													<strong style={{ fontSize: '12px' }}>
														{alcoholLicense.name}
													</strong>
												</Typography>
												<Button
													variant='outlined'
													color='error'
													onClick={resetAlcoholLicense}
												>
													Удалить
												</Button>
											</Grid>
										) : (
											<Grid sx={{ width: '50%' }}>
												<Button
													variant='outlined'
													component='label'
													sx={{ width: '100%' }}
												>
													Загрузить документ
													<input
														type='file'
														hidden
														onChange={alcoholLicenseChange}
														accept='.doc, .docx,.pdf'
													/>
												</Button>
											</Grid>
										)}
									</Grid>
									<Grid
										sx={{
											width: '100%',
											marginBottom: '25px',
											display: 'flex'
										}}
									>
										<Grid sx={{ width: '50%' }}>
											<p>Логотип организаций (необязательно):</p>
										</Grid>
										<Grid sx={{ width: '50%' }}>
											{selectImage ? (
												<Grid
													sx={{
														width: '100%',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center'
													}}
												>
													<img
														src={URL.createObjectURL(selectImage)}
														alt=''
														style={{ maxWidth: '50%', maxHeight: '320px' }}
													/>
													<Button
														sx={{ width: '100%' }}
														onClick={deleteSelectImage}
													>
														Удалить выбранный фото
													</Button>
												</Grid>
											) : (
												<Button
													variant='outlined'
													component='label'
													sx={{ width: '100%' }}
												>
													Загрузить файл
													<input
														type='file'
														hidden
														onChange={imageChange}
														accept='image/png, image/gif, image/jpeg'
													/>
												</Button>
											)}
										</Grid>
									</Grid>
									<Grid sx={{ width: '100%' }}>
										<FormControlLabel
											control={
												<Checkbox required onChange={handleCheckedChange} />
											}
											label={
												<>
													Я принимаю условия{' '}
													<a href='/terms' style={{ color: '#0837fe' }}>
														пользовательского соглашения
													</a>{' '}
													и даю согласие на обработку персональных данных
												</>
											}
										/>
									</Grid>
								</Paper>
							</Grid>
							<Grid item xs={12} md={6}>
								<Paper className='reg-cards' sx={{ marginBottom: '25px' }}>
									<h4>Контактные данные</h4>
									<Grid
										sx={{
											width: '100%',
											marginBottom: '25px',
											display: 'flex'
										}}
									>
										<TextField
											{...register('name')}
											required
											label='Имя:'
											variant='outlined'
											fullWidth
											sx={{ marginRight: '10px' }}
										/>
										<TextField
											{...register('surname')}
											required
											label='Фамилия:'
											variant='outlined'
											fullWidth
											sx={{ marginRight: '10px' }}
										/>
										<TextField
											{...register('patronymic')}
											label='Отчество:'
											variant='outlined'
											fullWidth
										/>
									</Grid>
									<Grid sx={{ width: '100%', marginBottom: '25px' }}>
										<TextField
											{...register('email')}
											required
											label='Электронная почта:'
											variant='outlined'
											fullWidth
											type='email'
										/>
									</Grid>
									<Grid sx={{ width: '100%', marginBottom: '25px' }}>
										<InputMask
											mask='+7(999) 999 9999'
											{...register('phoneNumber')}
											name='phoneNumber'
										>
											{inputProps => (
												<TextField
													required
													label='Номер телефона:'
													variant='outlined'
													fullWidth
													{...inputProps}
												/>
											)}
										</InputMask>
									</Grid>
								</Paper>
								<Paper className='reg-cards'>
									<h4>Данные авторизации</h4>
									<Grid sx={{ width: '100%', marginBottom: '25px' }}>
										<TextField
											{...register('login')}
											error={loginError}
											helperText={loginErrText}
											required
											label='Логин:'
											variant='outlined'
											fullWidth
											type='login'
											onChange={handleCheckLogin}
											sx={{ marginRight: '10px' }}
										/>
									</Grid>
									<Grid
										sx={{
											width: '100%',
											marginBottom: '25px',
											display: 'flex'
										}}
									>
										<TextField
											{...register('password')}
											required
											id='pass'
											label='Пароль:'
											variant='outlined'
											fullWidth
											type='password'
											sx={{ marginRight: '10px' }}
										/>
										<TextField
											error={passwordError}
											required
											label='Подтвердите пароль:'
											variant='outlined'
											type='password'
											onChange={handleCheckPassword}
											fullWidth
											helperText={passErrText}
										/>
									</Grid>
									<Grid sx={{ width: '100%' }}>
										<Grid
											sx={{
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												marginBottom: '20px'
											}}
										>
											<ReCAPTCHA
												ref={captchaRef}
												sitekey={Constants.RECAPTCHA_SITE_KEY}
											/>
										</Grid>
										<Button
											fullWidth
											variant='contained'
											disabled={!isCheckedTerms || passwordError}
											type='submit'
										>
											Завершить регистрацию
										</Button>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</form>
				</Container>
			)}
		</>
	);
}

export default withServices()(Registration);
